import React from "react";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Anti = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>
      <main className="anti_phishing_main">
        <div>
          <div className="Verification assets_main">
            <div className="container">
              <div>
                <Link to="/buycrypto">
                  {" "}
                  <h6>
                    {" "}
                    <i class="fa-solid fa-arrow-left-long mr-3"></i>
                    {t("quick-buy")}
                  </h6>
                </Link>
                <div className="row justify-content-center cards">
                  <div className="col-lg-4">
                    <span class="heading">{t("fill-the-details")}</span>

                    <div className="input-groups icons mt-4">
                      <h6 className="input-label">{t("card-number")}</h6>
                      <input
                        className="input-field"
                        placeholder={t("enter-card-number")}
                      />
                    </div>
                    <div className="input-groups icons">
                      <h6 className="input-label">{t("card-name")}</h6>
                      <input
                        className="input-field"
                        placeholder={t("enter-card-name")}
                      />
                    </div>

                    <div className="row">
                      <div className="input-groups col-lg-6 icons">
                        <h6 className="input-label">{t("expiry-date")}</h6>
                        <input
                          className="input-field"
                          placeholder="07-07-2024"
                        />
                      </div>
                      <div className="input-groups col-lg-6 icons">
                        <h6 className="input-label">CVV</h6>
                        <input
                          className="input-field"
                          placeholder={t("enter-cvv-number")}
                        />
                      </div>
                    </div>

                    <div className="Submit my-4">
                      <button>{t("confirm")}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Anti;
