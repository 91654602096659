import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { stakeOpt } from "../utils/mockData4";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PhoneInput from "react-phone-input-2";
import { Dropdown } from "semantic-ui-react";
import "react-phone-input-2/lib/style.css";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Side_bar from "./Side_bar";
import { Bars } from "react-loader-spinner";
import moment from "moment";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const [allCurrencyFiat, setfromCurrencyRef, fromCurrencyRef] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const currArrayCrypto = [
      {
        value: "BNB",
        key: "BNB",
        text: "BNB",
        image: {
          avatar: true,
          src: "https://res.cloudinary.com/taikonz-com/image/upload/v1664014615/fd2vqjmjipjxvzt6g2re.png",
        },
      },
      {
        value: "USDT",
        key: "USDT",
        text: "USDT",
        image: {
          avatar: true,
          src: "https://res.cloudinary.com/taikonz-com/image/upload/v1664014615/b15qia164vomylxkmqfp.png",
        },
      },
    ];
    setfromCurrencyRef(currArrayCrypto);
  }, [0]);

  const value = {
    Subject: "",
    Category: "Choose Category",
    text: "",
  };

  const [Formdata, setFormdata, Formdataref] = useState(value);

  const [SubjectErr, setSubjectErr] = useState(value);
  const [CategoryErr, setCategoryErr] = useState(value);
  const [textErr, settextErr] = useState(value);

  const [formErr, setformErr] = useState("");

  const [user, setuser] = useState([]);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalpages] = useState(0);
  // const [supportCategories, setCategories] = useState([]);
  const [viewChat, setViewChat] = useState(false);
  const [replyMess, replyMessage, replyMessref] = useState("");
  const [chatDatas, setChatDatas] = useState("");
  const [chatHistory, setchatHistory] = useState([]);

  const { Subject, Category, text } = Formdata;
  const [updateField, setUpdateField] = useState("");

  const getItem = (e) => {
    var { name, value } = e.target;
    if (name === "Category") {
      setUpdateField("category");
    }
    let check = { ...Formdata, ...{ [name]: value } };
    setFormdata(check);


    console.log(Formdataref.current.Subject.length);
    validate(Formdataref.current);
  };
  const [skipCount, setSkipCount] = useState(true);

  useEffect(() => {
    if (skipCount) setSkipCount(false);
    if (!skipCount) {
      validate(Formdata);
    }
  }, [updateField]);

  const recordPerPage = 5;
  const totalRecords = 15;
  const pageRange = 5;

  const handlePageChange = (event, pageNumber) => {
    viewData(pageNumber);
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    viewData(1);
    getCategory();
  }, [0]);
  const checkKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      reply();
    }
  };

  const validate = async (condition) => {
    var error = {};
    console.log(condition.Subject?.length,"condition.Subject?.length")
    if (condition.Subject == "") {
      error.Subject = t("subject-required-field");
      setSubjectErr(true);
    } else if (condition.Subject?.length < 5) {
      error.Subject = t("min-5-char-only-allowed");
      setSubjectErr(true);
    } else if (condition.Subject?.length > 50) {
      error.Subject = t("max-50-char-only-allowed");
      setSubjectErr(true);
    } else if (condition.Category === "Choose Category") {
      error.Category = t("category-required-field");
      setCategoryErr(true);
    } else if (condition.text == "") {
      error.text = t("message-required-field");
      settextErr(true);
    } else if (condition.text?.length < 5) {
      error.text = t("min-5-char-only-allowed");
      settextErr(true);
    } else if (condition.text?.length > 250) {
      error.text = t("max-250-char-only-allowed");
      settextErr(true);
    } else {
      settextErr(false);
      setCategoryErr(false);
      setSubjectErr(false);
    }
    setformErr(error);
  };

  const Movelogin = () => {
    navigate("/login");
  };
  const submit = async () => {
    validate(Formdata);
    // return false;
    if (
      Formdata.Subject != "" &&
      Formdata.Subject.length >= 5 &&
      Formdata.Subject.length <= 50 &&
      Formdata.Category != "Choose Category" &&
      Formdata.text != "" &&
      Formdata.text.length >= 5 &&
      Formdata.text.length <= 250
    ) {
      toast.success(t("your-ticket-created-successfully-please-wait"));
      var data = {
        apiUrl: apiService.createdSupport,
        payload: Formdata,
      };  
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      const obj = {
        Subject: "",
        Category: "Choose Category",
        text: "",
      };
      setFormdata(obj);
      viewData(1);
    } else {
      //toast.error("All are required fields");
    }
  };
  const viewData = async (page) => {
    try {
      var api = {
        apiUrl: apiService.findedSupport,
        payload: { perpage: 5, page: page ? page : 1 },
      };
      var view = await postMethod(api);
      if (view.status) {
        setuser(view.data.data);
        setTotalpages(view.data.total);
      }
    } catch (error) {}
  };

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loginCheck, setloginCheck] = useState(false);

  useEffect(() => {
    let userToken = localStorage.getItem("user_token");
    if (userToken) {
      setloginCheck(true);
    } else {
      setloginCheck(false);
    }
  }, []);
  const getCategory = async () => {
    try {
      var api = {
        apiUrl: apiService.supportCategories,
      };
      var response = await getMethod(api);
      if (response.status == true) {
        var data = [];
        for (var i = 0; i < response.data.length; i++) {
          var obj = {
            value: response.data[i].category,
            key: response.data[i]._id,
            text: response.data[i].category,
            label: response.data[i].category,
          };
          data.push(obj);
        }
        setCategories(data);
      }
    } catch (error) {}
  };

  const userChat = async (data) => {
    try {
      setChatDatas(data);
      setViewChat(true);
      var obj = {
        _id: data._id,
      };
      var api = {
        apiUrl: apiService.getSupportDetails,
        payload: obj,
      };
      var view = await postMethod(api);
      if (view) {
        setchatHistory(view.Message.reply);
      } else {
        setchatHistory([]);
      }
    } catch (error) {}
  };

const handleKeyDown = (e) => {
// Prevent space at the beginning of the input
if (e.key === ' ' && e.target.selectionStart === 0) {
e.preventDefault();
}
// Prevent validation when backspace is pressed and input is empty
if (e.key === 'Backspace' && e.target.value.length === 0) {
e.preventDefault();
}
};
  const closeTicket = async (data) => {
    try {
      var obj = {
        _id: data._id,
      };
      var api = {
        apiUrl: apiService.closeTicket,
        payload: obj,
      };
      var view = await postMethod(api);
      if (view) {
        // userChat(chatDatas);
        viewData(1);
        toast.success(view.Message);
      } else {
        toast.error(view.Message);
      }
    } catch (error) {}
  };

  const reply = async () => {
    try {
      if (Object.keys(replyMessref.current).length > 0) {
        var obj = {
          message: replyMessref.current,
          chatId: chatDatas._id,
          tag: "user",
          image: "",
          status: "0",
        };
        var api = {
          apiUrl: apiService.addUserSupport,
          payload: obj,
        };
        var view = await postMethod(api);
        if (view) {
          userChat(chatDatas);
          replyMessage("");
          toast.success(view.Message);
        }
      } else {
        toast.error(t("please-enter-message"));
      }
    } catch (error) {}
  };

  const onSelect = (event, data) => {
    setSelectedCategory(data.value); // Store selected value
    Formdata.Category = data.value;
    validate(Formdata);
  };

  return (
    <>
      <section>
        <Header />
      </section>

      <main className="dashboard_main">
        <div className="container">
          <div className="row">
            {loginCheck == true ? (
              <div className="col-lg-2">
                <Side_bar />
              </div>
            ) : (
              ""
            )}

            <div className="col-lg-10">
              <section className="asset_section">
                <div className="row">
                  <div className="withdraw_title_content">
                    <div className="withdraw_title">{t("support")}</div>
                  </div>
                  <div className="col-lg-7">
                    <div className="deposit mt-5">
                      <div className="form_div">
                        <div className="sides">
                          <div className="w-100 rights">
                            <h4>
                              {" "}
                              <span className="deposit-num">1</span>{" "}
                              {t("subject")}{" "}
                            </h4>
                            <input
                              type="text"
                              name="Subject"
                              maxLength={50}
                              value={Subject}
                              onChange={getItem}
                              onKeyDown={handleKeyDown}
                              placeholder={t("enter-the-subject")}
                              fluid
                              className="dep-drops"
                            />
                            {SubjectErr == true ? (
                              <p
                                className="errorcss"
                                style={{ textAlign: "left" }}
                              >
                                {formErr.Subject}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form_div ">
                        <h4>
                          {" "}
                          <span className="deposit-num">2</span>{" "}
                          {t("select-category")}
                        </h4>
                        {/* <select
                          className="dep-drops"
                          name="Category"
                          value={Category}
                          onChange={getItem}
                        >
                          <option selected disabled>
                            {t("choose-category")}
                          </option>
                          {supportCategories.length > 0 ? (
                            supportCategories.map((item, i) => (
                              <option selected>{item.category}</option>
                            ))
                          ) : (
                            <option selected>{t("choose-category")}</option>
                          )}
                        </select> */}
                        <Dropdown
                          placeholder={t("select-category")}
                          fluid
                          className="dep-drops"
                          selection
                          options={categories}
                          value={selectedCategory} // Control the dropdown value
                          onChange={onSelect} // Handle dropdown change
                          defaultValue={categories[0]?.value} // Set default value
                        />
                        {CategoryErr == true ? (
                          <p className="errorcss" style={{ textAlign: "left" }}>
                            {formErr.Category}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="form_div boder-none ">
                        <h4>
                          {" "}
                          <span className="deposit-num">3</span> {t("message")}
                        </h4>

                        <textarea
                          name="text"
                          value={text}
                          minLength={10}
                          maxLength={250}
                          onChange={getItem}
                          onKeyDown={handleKeyDown}
                          placeholder={t("enter-message")}
                          fluid
                          rows="5"
                          className="dep-drops support_textarea"
                        />
                        {textErr == true ? (
                          <p className="errorcss" style={{ textAlign: "left" }}>
                            {formErr.text}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                        {loginCheck == true ? (
                          <div className="sumbit_btn">
                            {buttonLoader == false ? (
                              <button onClick={submit}>{t("submit")}</button>
                            ) : (
                              <button>{t("loading")} ...</button>
                            )}
                          </div>
                        ) : (
                          <div className="sumbit_btn">
                            <button onClick={Movelogin}>
                              {t("login-to-continue")}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-5">
                    <div>
                      <div className="container">
                        <div
                          class="accordion accordion-flush"
                          id="accordionFlushExample"
                        >
                          <div className="faq mt-5">
                            <h5 className="faq-title">FAQ</h5>
                            <div class="accordion-item font-satoshi">
                              <h2
                                class="accordion-header"
                                id="flush-headingOne"
                              >
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseOne"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseOne"
                                >
                                  1. {t("what-is-a-support-ticket")}
                                </button>
                              </h2>
                              <div
                                id="flush-collapseOne"
                                class="accordion-collapse collapse"
                                aria-labelledby="flush-headingOne"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div class="accordion-body">
                                  {t("a-support-ticket-is-a-record")}
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item font-satoshi">
                              <h2
                                class="accordion-header"
                                id="flush-headingTwo"
                              >
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseTwo"
                                >
                                  2. {t("how-can-i-provide")}
                                </button>
                              </h2>
                              <div
                                id="flush-collapseTwo"
                                class="accordion-collapse collapse"
                                aria-labelledby="flush-headingTwo"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div class="accordion-body">
                                  {t("you-can-usually-provide")}
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item font-satoshi">
                              <h2
                                class="accordion-header"
                                id="flush-headingThree"
                              >
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseThree"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseThree"
                                >
                                  3. {t("what-information-should-i-provide")}
                                </button>
                              </h2>
                              <div
                                id="flush-collapseThree"
                                class="accordion-collapse collapse"
                                aria-labelledby="flush-headingThree"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div class="accordion-body">
                                  {t("when-contacting-support")}
                                </div>
                              </div>
                            </div>
                            {/* four */}
                            <div class="accordion-item font-satoshi">
                              <h2
                                class="accordion-header"
                                id="flush-headingFour"
                              >
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseFour"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseFour"
                                >
                                  4.{" "}
                                  {t("how-long-does-it-take-to-get-response")}
                                </button>
                              </h2>
                              <div
                                id="flush-collapseFour"
                                class="accordion-collapse collapse"
                                aria-labelledby="flush-headingFour"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div class="accordion-body">
                                  {t("response-times-can-very-depending")}
                                </div>
                              </div>
                            </div>
                            {/* five */}
                            <div class="accordion-item font-satoshi">
                              <h2
                                class="accordion-header"
                                id="flush-headingFive"
                              >
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseFive"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseFive"
                                >
                                  5. {t("can-i-get-support")}
                                </button>
                              </h2>
                              <div
                                id="flush-collapseFive"
                                class="accordion-collapse collapse"
                                aria-labelledby="flush-headingFive"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div class="accordion-body">
                                  {t("many-companies-offer")}
                                </div>
                              </div>
                            </div>
                            {/* six */}
                            <div class="accordion-item font-satoshi">
                              <h2
                                class="accordion-header"
                                id="flush-headingSix"
                              >
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseSix"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseSix"
                                >
                                  5. {t("what-should-i-do-if")}
                                </button>
                              </h2>
                              <div
                                id="flush-collapseSix"
                                class="accordion-collapse collapse"
                                aria-labelledby="flush-headingSix"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div class="accordion-body">
                                  {t("if-you-encounter-a-recurring")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {loginCheck == true ? (
                  <div className="dashboard_table">
                    <div className="staking-flex dash_assets">
                      <h5 className="opt-title">{t("support-history")}</h5>
                      {/* <div className="d-flex gap-2 text_green">
                        {t("view")} <i class="fa-solid fa-chevron-right"></i>
                      </div> */}
                    </div>

                    <div className="table-responsive table-cont dash_table_content">
                      <table className="table ">
                        <thead>
                          <tr className="stake-head font-satoshi">
                            <th>{t("ticket-id")}</th>
                            <th className="table_center_text">
                              {t("subject")}
                            </th>
                            <th className="table_center_text">
                              {t("message")}
                            </th>
                            <th className="table_center_text">
                              {t("category")}
                            </th>
                            <th className="table_action">{t("date-time")}</th>
                          </tr>
                        </thead>

                        <tbody>
                          {user.length > 0 ? (
                            user.map((data, i) => (
                              <tr>
                                <td className="opt-term font-satoshi font_14 table_center_frst">
                                  #{data._id.substring(0, 8)}
                                </td>
                                <td className="opt-term font-satoshi font_14 table_center_text">
                                 {data.subject}
                                </td>

                                

                                <td className="opt-term font-satoshi font_14 table_center_text ">
                              {data.message}
                                </td>
                                <td className="opt-term font-satoshi font_14 table_center_text">
                                  {data.category}
                                </td>
                                <td className="opt-term font-satoshi font_14 table_center_last">
                                  {moment(data.updated_at).format("lll")}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={5} className="text-center py-5">
                                <div className="empty_data">
                                  <div className="empty_data_img">
                                    <img
                                      src={require("../assets/No-data.png")}
                                      width="100px"
                                    />
                                  </div>

                                  <div className="no_records_text">
                                    {t("no-records-found")}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {user.length > 0 ? (
                        <div className="pagination">
                          <Stack spacing={2}>
                            <Pagination
                              count={Math.ceil(totalPage / recordPerPage)}
                              page={currentPage}
                              onChange={handlePageChange}
                              renderItem={(item) => (
                                <PaginationItem
                                  slots={{
                                    previous: ArrowBackIcon,
                                    next: ArrowForwardIcon,
                                  }}
                                  {...item}
                                  sx={{
                                    "&.Mui-selected": {
                                      backgroundColor: "#33c2b9",
                                      color: "#fff",
                                    },
                                  }}
                                />
                              )}
                              size="small"
                            />
                          </Stack>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </section>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Dashboard;
