import React, { useEffect } from "react";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isFocused ? "#33c2b9" : null,
      color: isFocused ? "#fff" : "#000",
      cursor: isDisabled ? "not-allowed" : "pointer",
    };
  },
};

const StakeHisTable = () => {
  const [stakeHistory, setstakeHistory, stakeHistoryref] = useState([]);
  const [siteLoader, setSiteLoader] = useState(false);
  const [choosingtype, setchoosingtype] = useState("flexible");

  const [toastId, setToastId] = useState(null);
  const { t } = useTranslation();

  const showSuccessToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.success(message);
    setToastId(newToastId);
  };

  const showErrorToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.error(message);
    setToastId(newToastId);
  };

  useEffect(() => {
    getStakingHistory(1);
  }, [0]);

  const [historyLoader, sethistoryLoader] = useState(false);

  const chooseHistory = async (value, page) => {
    setchoosingtype(value.value);
    if (value.value === "fixed") {
      sethistoryLoader(true);
      var data = {
        apiUrl: apiService.getFixedstakingHistory,
        payload: { FilPerpage: 5, FilPage: page ? page : 1, type: value },
      };
      var resp = await postMethod(data);
      sethistoryLoader(false);

      if (resp.status) {
        setstakeHistory(resp.data);
        // console.log(stakeHistoryref.current[0].type, "--=-=");
        settotal(resp.total);
      }
    }
    // else if (value === "All") {
    //   getStakingHistory(1);
    // }
    else {
      setchoosingtype("flexible");
      sethistoryLoader(true);
      var data = {
        apiUrl: apiService.getFlexiblestakingHistory,
        payload: { FilPerpage: 5, FilPage: page, type: value },
      };
      var resp = await postMethod(data);
      sethistoryLoader(false);
      if (resp.status) {
        setstakeHistory(resp.data);
        settotal(resp.total);
      }
    }
  };

  const getStakingHistory = async (page) => {
    try {
      sethistoryLoader(true);
      var data = {
        apiUrl: apiService.getFlexiblestakingHistory,
        payload: { FilPerpage: 5, FilPage: page, type: "flexible" },
      };
      setSiteLoader(true);
      var resp = await postMethod(data);
      setSiteLoader(false);
      sethistoryLoader(false);

      if (resp.status) {
        setstakeHistory(resp.data);
        console.log(stakeHistoryref.current[0], "--=-=");
        settotal(resp.total);
      }
    } catch (error) {
      // showErrorToast("Please try again later");
    }
  };

  const claimNow = async (claimData) => {
    try {
      var obj = {
        _id: claimData._id,
      };
      var data = {
        apiUrl: apiService.claimNowapi,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        showSuccessToast(resp.Message);
        getStakingHistory(1);
      } else {
        showErrorToast(resp.Message);
      }
    } catch (error) {
      // showErrorToast("Please try again later");
    }
  };

  const claimNowFlexible = async (claimData) => {
    try {
      var obj = {
        _id: claimData._id,
      };
      var data = {
        apiUrl: apiService.claimNowapiFlexible,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        showSuccessToast(resp.Message);
        getStakingHistory(1);
      } else {
        showErrorToast(resp.Message);
      }
    } catch (error) {
      // showErrorToast("Please try again later");
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [total, settotal] = useState(5);
  const recordPerPage = 5;

  const stakingOptions = [
    { value: "fixed", label: t("fixed") },
    { value: "flexible", label: t("flexible") },
  ];

  const handlePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber); // call API to get data based on pageNumber
    if (!!choosingtype) {
      chooseHistory(choosingtype, pageNumber);
    } else {
      getStakingHistory(pageNumber);
    }
  };

  return (
    <>
      {siteLoader == true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#33c2b9"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <>
          <div className="staking-flex">
            <div className="custom-select-stake mb-2">
              <div className="input-groups icons">
                {/* <div className="input-field ref_input upload_icon kyc_country_dropdown stake-opt-dropdown"></div> */}
                <div className="input-groups select-wrapper">
                  <Select
                    options={stakingOptions}
                    styles={colourStyles}
                    onChange={chooseHistory}
                    placeholder={stakingOptions[1].label}
                    // placeholder={t("all-items")}
                    className="reg-select-dropdown"
                  />
                </div>
              </div>
            </div>
            {/* <select
              name=""
              id=""
              className="opt-select"
              onClick={(e) => chooseHistory(e.target.value, "1")}
            >
              <option value="All">ALL</option>
              <option value="fixed">Fixed</option>
              <option value="flexible">Flexible</option>
            </select> */}

            <Link to="/stakingHistory">
              <div className="ref_view_main">
                <span className="invite_text">{t("view-all")}</span>
                <i
                  class="ri-arrow-right-s-line"
                  style={{ color: "#33C2B9" }}
                ></i>
              </div>
            </Link>
          </div>

          <div className="table-responsive table-cont">
            {historyLoader == true ? (
              <div className="stakeloadercss">
                <Bars
                  height="80"
                  width="80"
                  color="#33c2b9"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : (
              <>
                <table className="table">
                  <thead className="ref_table_head">
                    <tr className="stake-head font-satoshi thead-bor-bottom">
                      <th>{t("package")}</th>
                      <th className="opt-nowrap txt-center pad-left-23">
                        {t("total-amount")}
                      </th>
                      <th className="opt-nowrap txt-center pad-left-23">
                        APY/APR
                      </th>
                      <th className="opt-nowrap txt-center pad-left-23">
                        {t("type")}
                      </th>
                      {choosingtype == "fixed" ? (
                        <th className="opt-nowrap txt-center pad-left-23">
                          {t("interest-cycle")}
                        </th>
                      ) : (
                        ""
                      )}

                      <th className="opt-nowrap txt-center pad-left-23">
                        {t("stake-date")}
                      </th>

                      {choosingtype == "fixed" ? (
                        <th className="opt-nowrap txt-center pad-left-23">
                        {t("stake-end-date")}
                      </th>
                      ) : (
                        ""
                      )}
                    
                      {/* {choosingtype == "fixed" ? (
                        <th className="opt-nowrap txt-center pad-left-23">
                          {t("next-claim-date")}
                        </th>
                      ) : (
                        ""
                      )} */}

                      <th className="opt-nowrap txt-center pad-left-23">
                        {t("total-interest")}
                      </th>
                      <th className="opt-nowrap txt-center pad-left-23 history-tab-pl">
                        {" "}
                        {t("Claim")}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {stakeHistoryref.current &&
                    stakeHistoryref.current?.length > 0 ? (
                      stakeHistoryref.current.map((item, i) => {
                        var get_time = new Date(item.date).getTime();
                        var interest_cycle =
                          item.type == "fixed" ? item.stakingPlan : "";

                        var added_date =
                          get_time + +interest_cycle * 24 * 60 * 60 * 1000;

                        var claim_date = "";
                        if (item.type == "fixed") {
                          claim_date = item.endDate;
                        } else {
                          claim_date = "-";
                        }

                        claim_date = moment(claim_date).format("DD/MM/YYYY");
                        return (
                          <tr>
                            <td className="opt-percent font-satoshi font_14 px-2 pad-top-14px">
                              <img src={item.currencyImage} width="30px" />{" "}
                              {item.stakeCurrencsymbol}
                            </td>
                            <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                              {item.stakeAmont} {item.stakeCurrencsymbol}
                            </td>
                            <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                              {item.currentAPY}%
                            </td>
                            <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                              {item.type}
                            </td>
                            {item.type == "fixed" ? (
                              <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                {item.type == "fixed"
                                  ? item.stakingPlan + " days"
                                  : "-"}
                              </td>
                            ) : (
                              ""
                            )}
                            <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                              {moment(item.startDate).format("DD/MM/YYYY")}
                            </td>
                            {item.type == "fixed" ? (
                            <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                            {item.type == "fixed"
                              ? moment(item.endDate).format("DD/MM/YYYY")
                              : "-"}
                          </td>
                            ) : (
                              ""
                            )}
                            {/* {item.type == "fixed" ? (
                              <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                {item.type == "fixed"
                                  ? moment(item.endDate).format("DD/MM/YYYY")
                                  : "-"}
                              </td>
                            ) : (
                              ""
                            )} */}
                            <td>
                              <div className="minimum">
                                {item.type == "fixed" ? (
                                  <p className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                    {parseFloat(item.totalInterest).toFixed(6)}{" "}
                                    {item.stakeCurrencsymbol}
                                  </p>
                                ) : (
                                  <p className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                    {parseFloat(item.totalInterest).toFixed(6)}{" "}
                                    {item.stakeCurrencsymbol}
                                  </p>
                                )}
                              </div>
                            </td>
                            <td className="opt-btn-flex table_action pad-left-23">
                              {item.type == "fixed" ? (
                                <div className="minimum">
                                  {item.status == 1 ? (
                                    <button
                                      className="active"
                                      onClick={() => claimNow(item)}
                                    >
                                      Claim Now
                                    </button>
                                  ) : item.status == 0 ? (
                                    <button className="notactive">
                                      {" "}
                                      Claim
                                    </button>
                                  ) : (
                                    <button className="notactive">
                                      {" "}
                                      Claimed
                                    </button>
                                  )}
                                </div>
                              ) : (
                                <div className="minimum">
                                  {item.status == 0 ? (
                                    <button
                                      className="active"
                                      onClick={() => claimNowFlexible(item)}
                                    >
                                      Claim Now
                                    </button>
                                  ) : (
                                    <button className="notactive">
                                      Claimed
                                    </button>
                                  )}
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={9} className="text-center py-5">
                          <div className="empty_data">
                            <div className="empty_data_img">
                              <img
                                src={require("../assets/No-data.png")}
                                width="100px"
                              />
                            </div>
                            <div className="no_records_text">
                              {t("no-records-found")}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {stakeHistoryref.current &&
                stakeHistoryref.current.length > 0 ? (
                  <div className="pagination">
                    <Stack spacing={2}>
                      <Pagination
                        count={Math.ceil(total / recordPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        size="small"
                        renderItem={(item) => (
                          <PaginationItem
                            slots={{
                              previous: ArrowBackIcon,
                              next: ArrowForwardIcon,
                            }}
                            {...item}
                            sx={{
                              "&.Mui-selected": {
                                backgroundColor: "#33c2b9",
                                color: "#fff",
                              },
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default StakeHisTable;
