import React, { useEffect } from "react";
import Header from "./Header";
import { stakeOpt } from "../utils/mockData2";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Side_bar from "./Side_bar";
import Markettable from "./Markettable";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import Moment from "moment";
import { useTranslation } from "react-i18next";
import { socket } from "../context/socket";

const Market = () => {
  const [currencylistData, setcurrencylistData] = useState([]);
  const [currencyCount, setcurrencyCount] = useState(0);
  const [siteLoader, setSiteLoader] = useState(false);
  const [loginCheck, setloginCheck] = useState(false);
  const [search, setsearch, searchref] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    let userToken = localStorage.getItem("user_token");
    if (userToken) {
      setloginCheck(true);
    } else {
      setloginCheck(false);
    }
    console.log("=-=-=-=response=-se");
    socket.on("homepagemarketprice", async (response) => {
      console.log("=-=-=-=response=-=-=-=-=-=-response");
      console.log(response, "=-=-=-=response=-=-=-=-=-=-response");
      if (response.data) {
      }
    });
    // .then((res=>console.log(res))).catch((err)=>console.log(err))

    gethomeCurrency();
  }, [socket]);

  const navtradepage = async (symbol) => {
    window.location.href = `trade/${symbol}_EUR`;
  };

  const gethomeCurrency = async () => {
    var data = {
      apiUrl: apiService.homeCurrency,
    };

    setmarketLoader(true)
    var resp = await getMethod(data);
    setmarketLoader(false)

    if (resp.status) {
      setcurrencylistData(resp.Message);
    } else {
    }
  };

  const handleChange = (e) => {
    try {
      const sanitizedValue = e.target.value.replace(/\s/g, "");
      console.log(sanitizedValue, "-=-sanitizedValue=-=");
      setsearch(sanitizedValue);
      searchCurrency();
    } catch (error) {
      console.error(error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const [marketLoader,setmarketLoader]= useState(false);
  const searchCurrency = async () => {
    console.log(searchref.current, "-=-=searchref.current=--");
    if (
      searchref.current !== "" &&
      searchref.current !== undefined &&
      searchref.current !== null
    ) {
      const regexPattern = new RegExp(searchref.current, "i");
      if (currencylistData.length > 0) {
        const searchWallet = currencylistData.filter((data) =>
          regexPattern.test(data.currencySymbol)
        );
        if (searchWallet.length > 0) {
          setcurrencylistData(searchWallet);
        } else {
          setcurrencylistData([]);
        }
      }
      // const searchWallet = balanceDatas.filter(data => data.currencysymbol.toLowerCase() === searchref.current.toLowerCase());
    } else {
      gethomeCurrency();
    }
  };

  return (
    <>
      <section>
        <Header />
      </section>

     
        <main className="dashboard_main h-100">
          <div className="container">
            <div className="row">
              {loginCheck == true ? (
                <div className="col-lg-2">
                  <Side_bar />
                </div>
              ) : (
                ""
              )}
 {siteLoader == true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#33c2b9"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
              <div className={loginCheck == true ? "col-lg-10" : "col-lg-12"}>
                <section className="asset_section">
                  <div className="row">
                    <div className="buy_head">
                      <div className="buy-rewards">
                        <span className="reward-title">{t("market")}</span>
                        <div className="stake-search-container">
                          <input
                            type="text"
                            placeholder={t("search")}
                            className="stake-input"
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            maxLength={10}
                          />
                          <i
                            className="fa-solid fa-magnifying-glass"
                            onClick={() => searchCurrency()}
                          ></i>
                        </div>
                      </div>
                      <ul className="history-lists">
                        <Link className="history-links active">
                          {t("spot")}
                        </Link>
                      </ul>

                      {/* <Markettable/> */}

                      <div className="table-responsive table-cont dash_table_content">
                        <table className="table ">
                          <thead>
                            <tr className="stake-head font-satoshi">
                              <th>{t("name")}</th>
                              <th className="opt-nowrap pad-left-23 px-0">
                                {/* {t("price")} (USD) */}
                                {t("price")} (EUR)
                              </th>
                              <th className="table_action tab-pad-r-10">
                                {t("trade")}
                              </th>
                            </tr>
                          </thead>

                          {marketLoader == true ? (
                              <tr>
                              <td colSpan={3} className="text-center py-5">
                                <div className="empty_data">
                                  <div className="empty_data_img loadercss2">
                                   <Bars
                                     height="80"
                                     width="80"
                                     color="#33c2b9"
                                     ariaLabel="bars-loading"
                                     wrapperStyle={{}}
                                     wrapperClass=""
                                     visible={true}
                                   />
                                  </div>
                                
                                </div>
                              </td>
                            </tr>
        
      ) : (
                          <tbody>
                            {currencylistData && currencylistData.length > 0 ? (
                              currencylistData.map((obj, i) => {
                                return (
                                  <tr key={i}>
                                    <td className="table-flex">
                                      <img src={obj.Currency_image} alt="" />
                                      <div className="table-opt-name">
                                        <h4 className="opt-name font-satoshi font_14">
                                          {obj.currencyName}
                                        </h4>
                                        <h3 className="opt-sub font-satoshi font_14">
                                          {obj.currencySymbol}
                                        </h3>
                                      </div>
                                    </td>

                                    <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 tab-pad-l-10">
                                      {obj.marketprice <= 0 ? (
                                        <td className="text-danger">
                                          {obj.currencySymbol == "SHIB" ? (
                                            <span className="">
                                              {parseFloat(
                                                obj.estimatedValueInUSDT
                                              ).toFixed(8)}
                                            </span>
                                          ) : (
                                            <span className="">
                                              {parseFloat(
                                                obj.estimatedValueInUSDT
                                              ).toFixed(2)}
                                            </span>
                                          )}
                                        </td>
                                      ) : (
                                        <td className="text-success">
                                          {obj.currencySymbol == "SHIB" ? (
                                            <span className="">
                                              {parseFloat(
                                                obj.estimatedValueInUSDT
                                              ).toFixed(8)}
                                            </span>
                                          ) : (
                                            <span className="">
                                              {parseFloat(
                                                obj.estimatedValueInUSDT
                                              ).toFixed(2)}
                                            </span>
                                          )}
                                        </td>
                                      )}
                                    </td>

                                    <td className="opt-btn-flex table_action pad-left-23">
                                      <div className="deposit_top_button">
                                        <button
                                          className="action_btn "
                                          onClick={() =>
                                            navtradepage(obj.currencySymbol)
                                          }
                                        >
                                          {t("trade")}
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={3} className="text-center py-5">
                                  <div className="empty_data">
                                    <div className="empty_data_img">
                                      <img
                                        src={require("../assets/No-data.png")}
                                        width="100px"
                                      />
                                    </div>
                                    <div className="no_records_text">
                                      {t("no-records-found")}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
      )}
                        </table>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
      )}
            </div>
          </div>
        </main>
    </>
  );
};

export default Market;
