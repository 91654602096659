import React from "react";
import QR from "../assets/qr.png";
import Header from "./Header";
import { useTranslation } from "react-i18next";

const EnableTFA = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />{" "}
      <div>
        <div className="Verification enable2fa-pt">
          <div className="container">
            <div className="">
              <h6>
                {" "}
                <i class="fa-solid fa-arrow-left-long mr-3"></i> {t("security")}
              </h6>
              <div className="row justify-content-center cards ">
                <div className="col-lg-4">
                  <span class="heading">{t("enable-space-2fa")}</span>

                  <div className="notify">
                    {" "}
                    {t("protect-your-transactions")}{" "}
                  </div>

                  <div className="input-groups qr">
                    <img src={QR} alt="QR" />
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label">{t("2fa-code")}</h6>
                    <input
                      className="input-field"
                      placeholder={t("enter-code")}
                    />
                    <span className="textgreen eyeicons"> 56 s </span>
                  </div>

                  <div className="Submit my-4">
                    <button>{t("enable")}</button>
                  </div>

                  <div className="foot">
                    <p>
                      {t("didnt-receive-code")} <a>{t("resend")}</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnableTFA;
