import React, { useEffect } from "react";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { setAuthorization } from "../core/service/axios";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Box, Modal } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const Login = () => {
  const initialFormValue = {
    email: "",
    phonenumber: "",
    password: "",
  };

  const { t } = useTranslation();

  const [emailValidate, setemailValidate, emailValidateref] = useState(false);
  const [phoneNumberValidate, setPhoneNumberValidate, phoneNumberValidateref] =
    useState(false);
  const [passwordValidate, setpasswordValidate, passwordValidateref] =
    useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");

  const [loginType, setLoginType, loginTyperef] = useState("Email");

  const { email, phonenumber, password } = formValue;

  const navigate = useNavigate();

  useEffect(() => {
    var token = localStorage.getItem("user_token");
    if (token) {
      navigate("/dashboard");
    }
  }, []);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    let sanitizedValue = value.replace(/\s/g, "");
    if (name === "phonenumber") {
      // Allow only digits and limit to 10 characters
      sanitizedValue = sanitizedValue.replace(/\D/g, "").slice(0, 10);
    }
    let formData = { ...formValue, ...{ [name]: sanitizedValue } };
    setFormValue(formData);
    validate(formData);
  };

  const handlePhoneChange = (value) => {
    setFormValue({ ...formValue, phonenumber: value });
    validate({ ...formValue, phonenumber: value });
  };

  const typeChange = (value) => {
    setLoginType(value);
    setFormValue(initialFormValue);
    setemailValidate(false);
    setPhoneNumberValidate(false);
    setpasswordValidate(false);
    setvalidationnErr("");
  };


  const handleKeyDown = (e) => {
    if (e.key === ' ' && e.target.selectionStart === 0) {
      e.preventDefault();
      }
  };

  const validate = (values) => {
    let errors = {};
    const username = values.email.split("@")[0];

    if (loginTyperef.current == "Email") {
      if (!values.email) {
        errors.email = t("email-required-field");
        setemailValidate(true);
      } else if (
        !/^[a-zA-Z0-9.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = t("invalid-email-address");
        setemailValidate(true);
      }
      //  else if (username.length < 4 || username.length > 30) {
      //   errors.email = t("email-username-must-b/w-4-30");
      //   setemailValidate(true);
      // } 
      else if (!/^[a-zA-Z0-9.]+$/i.test(username)) {
        errors.email = t("only-letters-numbers-and-periods-allowed");
        setemailValidate(true);
      } else if (!/[a-zA-Z]/.test(username)) {
        errors.email = t("email-username-contain-atleast-one-letter");
        setemailValidate(true);
      } 
      // else if (values.email.length > 50) {
      //   errors.email = t("email-address-too-long");
      //   setemailValidate(true);
      // } 
      else if (values.password == "") {
        setpasswordValidate(true);
        errors.password = t("password-required");
      } 
      // else if (values.password.length < 12 || values.password.length > 25) {
      //   setpasswordValidate(true);
      //   errors.password = t("passoword-should-not-below-5-above-25");
      // } 
      else if (!values.password.match(/[a-z]/g)) {
        setpasswordValidate(true);
        errors.password = t("please-enter-at-least-lower-char");
      } else if (!values.password.match(/[A-Z]/g)) {
        setpasswordValidate(true);
        errors.password = t("please-enter-at-least-upper-char");
      } else if (!values.password.match(/[0-9]/g)) {
        setpasswordValidate(true);
        errors.password = t("please-enter-one-digit-char");
      } else if (!values.password.match(/[!@#$%^&*]/g)) {
        setpasswordValidate(true);
        errors.password = t("please-enter-at-least-one-special-char");
      } else {
        setemailValidate(false);
        setpasswordValidate(false);
        setPhoneNumberValidate(false);
      }
      //  else {
      //   // setemailValidate(false);
      // }
    } else if (loginTyperef.current == "PhoneNumber") {
      if (values.phonenumber == "") {
        setPhoneNumberValidate(true);
        errors.phonenumber = t("phone-number-required");
      } else if (values.phonenumber.length < 5) {
        errors.phonenumber = t("phone-num-should-not-below-5");
        setPhoneNumberValidate(true);
      } else if (values.password == "") {
        setpasswordValidate(true);
        errors.password = t("password-required");
      } else if (values.password.length < 5 || values.password.length > 15) {
        setpasswordValidate(true);
        errors.password = t("passoword-should-not-below-5-above-25");
      } else if (!values.password.match(/[a-z]/g)) {
        setpasswordValidate(true);
        errors.password = t("please-enter-at-least-lower-char");
      } else if (!values.password.match(/[A-Z]/g)) {
        setpasswordValidate(true);
        errors.password = t("please-enter-at-least-upper-char");
      } else if (!values.password.match(/[0-9]/g)) {
        setpasswordValidate(true);
        errors.password = t("please-enter-one-digit-char");
      } else if (!values.password.match(/[!@#$%^&*]/g)) {
        setpasswordValidate(true);
        errors.password = t("please-enter-at-least-one-special-char");
      } else {
        setemailValidate(false);
        setpasswordValidate(false);
        setPhoneNumberValidate(false);
      }
      //  else {
      //   setPhoneNumberValidate(false);
      // }
    }

    setvalidationnErr(errors);

    return errors;
  };

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const [toastId, setToastId] = useState(null);

  const showSuccessToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.success(message);
    setToastId(newToastId);
  };

  const showErrorToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.error(message);
    setToastId(newToastId);
  };

  const formSubmit = async () => {
    validate(formValue);
    if (loginTyperef.current === "Email") {
      if (
        emailValidateref.current === false &&
        passwordValidateref.current === false
      ) {
        formValue["logintype"] = "Email";
        localStorage.setItem("useremail", formValue.email);
        var data = {
          apiUrl: apiService.signin,
          payload: formValue,
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setbuttonLoader(false);
        setFormValue(initialFormValue);
        if (resp?.tfa === 1) {
          navigate("/tfa", {
            state: {
              socketToken: resp?.socketToken,
            },
          });
        } else {
          if (resp.status == true) {
            setFormValue(initialFormValue);
            showSuccessToast(resp.Message);
            await setAuthorization(resp.token);
            localStorage.setItem("user_token", resp.token);
            localStorage.setItem("tfa_status", resp.tfa);
            localStorage.setItem("socket_token", resp.socketToken);
            localStorage.setItem("jwNkiKmttscotlox", resp.jwNkiKmttscotlox);
            localStorage.setItem("registe_type", resp.registerType);
            navigate("/dashboard");
          } else {
            if (
              resp.Message ==
              "Please verify your account or contact the administrator for assistance."
            ) {
              handleOpen();
              setbuttonLoader(false);
            }else{
              setbuttonLoader(false);
              showErrorToast(resp.Message);
            }
          
          }
        }
      }
    } else {
      if (
        phoneNumberValidateref.current === false &&
        passwordValidateref.current === false
      ) {
        formValue["logintype"] = "PhoneNumber";
        var data = {
          apiUrl: apiService.signin,
          payload: formValue,
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setbuttonLoader(false);
        setFormValue(initialFormValue);
        if (resp?.tfa === 1) {
          navigate("/tfa", {
            state: {
              socketToken: resp?.socketToken,
            },
          });
        } else {
          if (resp.status == true) {
            setFormValue(initialFormValue);
            showSuccessToast(resp.Message);
            await setAuthorization(resp.token);
            localStorage.setItem("user_token", resp.token);
            localStorage.setItem("tfa_status", resp.tfa);
            localStorage.setItem("socket_token", resp.socketToken);
            localStorage.setItem("jwNkiKmttscotlox", resp.jwNkiKmttscotlox);
            localStorage.setItem("registe_type", resp.registerType);
            navigate("/dashboard");
          } else {
            setbuttonLoader(false);
            showErrorToast(resp.Message);
          }
        }
      }
    }
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const [verifyLoader,setverifyLoader]= useState(false);

  const verify_email = async () => {
    try {
      var obj = {
        email: localStorage.getItem("useremail"),
      };

      var data = {
        apiUrl: apiService.resendCode,
        payload: obj,
      };
      setverifyLoader(true);
      var resp = await postMethod(data);
      setverifyLoader(false);
      if (resp.status) {
        showSuccessToast(resp.Message);
        navigate("/verification");
      } else {
        showErrorToast(resp.Message);
      }
    } catch (error) {
      setverifyLoader(false);
    }
  };

  return (
    <>
      <Header />
      <div>
        <div className="register">
          <div className="container">
            <div className="row reg-row">
              <div className="col-lg-4">
                <div className="register-left">
                  <img src={require("../assets/register.webp")} />
                  <h4>{t("trade-earn-rewards")}</h4>
                  <h5>
                    {t("trade-earn-rewards-content1")} <br />{" "}
                    {t("trade-earn-rewards-content2")}
                  </h5>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="register-card">
                  <span class="heading">{t("signin-fidex")}</span>

                  {/* toggle buttons (email and phone)*/}
                  <div class="nav nav-tabs mt-2" id="nav-tab" role="tablist">
                    <button
                      class="nav-link active"
                      id="nav-home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-home"
                      type="button"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                      onClick={() => typeChange("Email")}
                    >
                      {t("email")}
                    </button>
                    {/* <button
                      class="nav-link "
                      id="nav-profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-profile"
                      type="button"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                      onClick={() => typeChange("PhoneNumber")}
                    >
                      {t("phone-no")}
                    </button> */}
                  </div>

                  <div class="tab-content" id="nav-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="nav-home"
                      role="tabpanel"
                      aria-labelledby="nav-home-tab"
                      tabindex="0"
                    >
                      <div className="input-groups ">
                        <h6 className="input-label">{t("email")}</h6>
                        <input
                          type="text"
                          name="email"
                          value={email}
                          minLength={3}
                          maxLength={250}
                          onKeyDown={handleKeyDown}
                          onChange={handleChange}
                          className="input-field"
                          placeholder={t("enter-email")}
                        />
                        {validationnErr && validationnErr.email && (
                          <p className="errorcss">{validationnErr.email}</p>
                        )}
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="nav-profile"
                      role="tabpanel"
                      aria-labelledby="nav-profile-tab"
                      tabindex="0"
                    >
                      <div className="input-groups ">
                        <h6 className="input-label">{t("phone-no")}</h6>
                        <PhoneInput
                          country={"us"}
                          value={phonenumber}
                          onChange={handlePhoneChange}
                          inputProps={{
                            name: "phonenumber",
                            required: true,
                            className: "input-phone",
                          }}
                        />
                        {validationnErr && validationnErr.phonenumber && (
                          <p className="errorcss">
                            {validationnErr.phonenumber}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="input-groups icons">
                    <h6 className="input-label">{t("password")}</h6>
                    <div className="flex_input_posion">
                      <input
                        type={inputType}
                        name="password"
                        value={password}
                        minLength={12}
                        maxLength={25}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        className="input-field"
                        placeholder={t("enter-password")}
                      />
                      {passHide == true ? (
                        <i
                          class="fa-regular fa-eye eyeicons"
                          onClick={() => passwordHide("show")}
                        ></i>
                      ) : (
                        <i
                          class="fa-regular fa-eye-slash eyeicons"
                          onClick={() => passwordHide("hide")}
                        ></i>
                      )}
                    </div>
                    {validationnErr && validationnErr.password && (
                      <p className="errorcss">{validationnErr.password}</p>
                    )}
                    {/* <img
                      src={require("../assets/Eye.png")}
                      width="20px"
                      className="eyeicons"
                    /> */}
                  </div>

                  <div className="terms">
                    <p>
                      <Link to="/forgotpassword">{t("forget-password")}</Link>
                    </p>
                  </div>

                  <div className="Submit" onClick={formSubmit}>
                    {buttonLoader == false ? (
                      <button>{t("signin")}</button>
                    ) : (
                      <button>{t("loading")}...</button>
                    )}
                  </div>

                  <div className="foot">
                    <p>
                      {t("don't-have-account")}{" "}
                      <Link to="/register">{t("signUp")}</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* email verify popup card */}

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="email-popup-modal-title"
              aria-describedby="email-popup-modal-description"
            >
              <Box>
                <div className="email-popup-card">
                  <div className="email-pop-icon">
                    <img
                      src={require("../assets/email-pop-close.png")}
                      alt="close"
                      onClick={handleClose}
                      className="email-pop-close"
                    />
                    <img
                      src={require("../assets/email-pop-close-dark.png")}
                      alt="close"
                      onClick={handleClose}
                      className="email-pop-close-darkk"
                    />
                  </div>
                  <div className="email-pop-img">
                    <img
                      src={require("../assets/email-pop.webp")}
                      alt="email-icon"
                      className="email-pop-imgg"
                    />
                    <img
                      src={require("../assets/email-pop-dark.png")}
                      alt="email-icon"
                      className="email-pop-img-dark"
                    />
                  </div>
                  <h3>{t("email-verify-require")}</h3>
                  <p>{t("email-verify-require-content")}</p>
                  {verifyLoader ==false ?
                    <div className="Submit">
                    <button onClick={verify_email}>{t("verify-now")}</button>
                  </div>
                  :
                 <div className="Submit">
                 <button>{t("loading")}</button>
                </div>
                  }
                
                 
                </div>
              </Box>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
