import React, { useEffect, useMemo } from "react";
import Header from "./Header";
import useState from "react-usestateref";
import { Link, useNavigate } from "react-router-dom";
import { postMethod, getMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import countryList from "react-select-country-list";
import { env } from "../core/service/envconfig";
import { useTranslation } from "react-i18next";

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isFocused ? "#33c2b9" : null,
      color: isFocused ? "#fff" : "#000",
      cursor: isDisabled ? "not-allowed" : "pointer",
    };
  },
};

export default function FiatDeposit() {
  // const [value, setValue] = useState("");
  const [selectPerson, setSelectPerson, selectPersonref] = useState("yes");
  const [selectAccess, setSelectAccess, selectAccessref] = useState("yes");
  const [selectAssociate, setSelectAssociate, selectAssociateref] =
    useState("yes");
  const [selectLicense, setSelectLicense] = useState(false);
  const [credit, setCredit] = useState(false);
  const { t } = useTranslation();
  const options = useMemo(() => countryList().getData(), []);
  useEffect(() => {
    getStep();
  }, [0]);
  // const changeHandler = (value) => {
  //   setValue(value);
  // };

  const navigate = useNavigate();

  // access to the account
  const handleYesAccess = () => {
    setSelectPerson("yes");
  };
  const handleNoAccess = () => {
    setSelectPerson("no");
  };

  // account holder
  const accountYes = () => {
    setSelectAccess("yes");
  };
  const accountNo = () => {
    setSelectAccess("no");
  };

  // relative or associate
  const handleYesAssociate = () => {
    setSelectAssociate("yes");
  };
  const handleNoAssociate = () => {
    setSelectAssociate("no");
  };

  const handleCredit = () => {
    setCredit(!credit);
  };
  const handleLicense = () => {
    setSelectLicense(!selectLicense);
  };

  // const handleCheckComp = () => {
  //   setSelectCompCheck((prevCheck) => !prevCheck);
  // };
  // const handleShowStatement = () => {
  //   setIsShowStament(!isShowStatement);
  // };
  // const handleCheckProof = () => {
  //   setSelectProof((prevCheck) => !prevCheck);
  // };

  const initialFormValue = {
    firstName: "",
    surname: "",
    dob: "",
    address: "",
    unit: 0,
    city: "",
    phoneNumber: "",
    postalCode: "",
    country: "",
    nationality: "",
    associateFirstname: "",
    associateSurname: "",
    relation: "",
    identityDocument: "",
    addressProof: "",
  };

  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [firstNameValidate, setfirstNameValidate, firstNameValidateref] =
    useState(false);
  const [surnameValidate, setsurnameValidate, surnameValidateref] =
    useState(false);
  const [dobValidate, setdobValidate, dobValidateref] = useState(false);
  const [addressValidate, setaddressValidate, addressValidateref] =
    useState(false);
  const [cityValidate, setcityValidate, cityValidateref] = useState(false);
  const [phoneNumberValidate, setphoneNumberValidate, phoneNumberValidateref] =
    useState(false);
  const [postalCodeValidate, setpostalCodeValidate, postalCodeValidateref] =
    useState(false);
  const [countryValidate, setcountryValidate, countryValidateref] =
    useState(false);
  const [nationalityValidate, setnationalityValidate, nationalityValidateref] =
    useState(false);
  const [
    associateFirstnameValidate,
    setassociateFirstnameValidate,
    associateFirstnameValidateref,
  ] = useState(false);
  const [
    associateSurnameValidate,
    setassociateSurnameValidate,
    associateSurnameValidateref,
  ] = useState(false);
  const [relationValidate, setrelationValidate, relationValidateref] =
    useState(false);
  const [
    identityDocumentValidate,
    setidentityDocumentValidate,
    identityDocumentValidateref,
  ] = useState(false);
  const [unitValidate, setunitValidateValidate, unitValidateValidateref] =
    useState(false);
  const [
    addressProofValidate,
    setaddressProofValidate,
    addressProofValidateref,
  ] = useState(false);
  const [valididproof, setvalididproof] = useState(0);
  const [idproofLoad, setidproofLoad] = useState(false);
  const [idproof, setidproof, idproofref] = useState("");
  const [idproofname, setidproofname, idproofnameref] = useState("");
  const [validaddressProof, setvalidaddressProof] = useState(0);
  const [addressProofup, setaddressProof, addressProofref] = useState("");
  const [addressproofname, setaddressproofname, addressproofnameref] =
    useState("");
  const [addressProofLoad, setaddressProofLoad] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);

  const {
    firstName,
    surname,
    dob,
    address,
    unit,
    city,
    phoneNumber,
    postalCode,
    country,
    nationality,
    associateFirstname,
    associateSurname,
    relation,
    identityDocument,
    addressProof,
  } = formValue;

  const relationOptions = [
    { value: "Spouse", label: t("spouse") },
    { value: "Child", label: t("child") },
    { value: "Parent", label: t("parent") },
    { value: "Associate", label: t("associate") },
  ];

  const documentOptions = [
    { value: "Passport", label: t("passport") },
    { value: "Driver's license", label: t("driver-license") },
    { value: "ID Card", label: t("id-card") },
  ];

  const addressProofOptions = [
    { value: "Bank Statement", label: t("bank-statement") },
    { value: "Credit Card Statement", label: t("credit-card-statement") },
    { value: "Utility Bill", label: t("utility-bill") },
    { value: "Salary Slip", label: t("salary-slip") },
    { value: "Insurance Statement", label: t("insurance-statement") },
    { value: "Tax Document", label: t("tax-document") },
    { value: "Residence Certificate", label: t("residence-certificate") },
  ];

  const getTodayDate = () => {
    return new Date().toISOString().split("T")[0];
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    // let sanitizedValue = value.replace(/\s/g, "");
    let sanitizedValue = value;
    if (name === "phonenumber") {
      
      sanitizedValue = sanitizedValue.replace(/\D/g, "").slice(0, 10);
    }

    let formData = { ...formValue, ...{ [name]: sanitizedValue } };

    setFormValue(formData);
    validate(formData);
  };

  const handleCountryChange = (selectedCountry) => {
    const correspondingNationality = options.find(
      (option) => option.value === selectedCountry.value
    );

    const updatedFormValue = {
      ...formValue,
      country: selectedCountry.value,
      // nationality: correspondingNationality
      //   ? correspondingNationality.value
      //   : "",
    };

    setFormValue(updatedFormValue);
    validate(updatedFormValue); // Assuming validate is a function to validate the form
  };

  const handleNationalityChange = (selectedNationality) => {
    const updatedFormValue = {
      ...formValue,
      nationality: selectedNationality.value,
    };

    setFormValue(updatedFormValue);
    validate(updatedFormValue);
  };

  const handleRelationChange = (selectedRelation) => {
    const updatedFormValue = {
      ...formValue,
      relation: selectedRelation ? selectedRelation.value : "",
    };

    setFormValue(updatedFormValue);
    validate(updatedFormValue); // Assuming validate is a function to validate the form
  };

  const handleDocumentChange = (selectedOption) => {
    const updatedFormValue = {
      ...formValue,
      identityDocument: selectedOption.value,
    };
    setFormValue(updatedFormValue);
    validate(updatedFormValue);
  };

  const handleAddressProofChange = (selectedOption) => {
    const updatedFormValue = {
      ...formValue,
      addressProof: selectedOption.value,
    };
    setFormValue(updatedFormValue);
    validate(updatedFormValue);
  };

  const handlePhoneChange = (value) => {
    setFormValue({ ...formValue, phoneNumber: value });
    validate({ ...formValue, phoneNumber: value });
  };

  const validate = (values) => {
    let errors = {};
    // const username = values.email.split("@")[0];

    if (!values.firstName) {
      errors.firstName = t("first-name-required");
      setfirstNameValidate(true);
    } else if (!/^[a-zA-Z]/.test(values.firstName)) {
      errors.firstName = t("firstname-must-start-letter");
      setfirstNameValidate(true);
    } else if (values.firstName.length < 3 || values.firstName.length > 25) {
      errors.firstName = t("firstname-must-3-25-char");
      setfirstNameValidate(true);
    } else if (!/^[a-zA-Z_]+$/.test(values.firstName)) {
      errors.firstName = t("fname-only-contain-letter-underscore");
      setfirstNameValidate(true);
    } else if (!values.surname) {
      errors.surname = t("surname-required");
      setsurnameValidate(true);
    } else if (!/^[a-zA-Z]/.test(values.surname)) {
      errors.surname = t("surname-must-start-letter");
      setsurnameValidate(true);
    } else if (values.surname.length < 3 || values.surname.length > 25) {
      errors.surname = t("surname-must-3-25-char");
      setsurnameValidate(true);
    } else if (!/^[a-zA-Z_]+$/.test(values.surname)) {
      errors.surname = t("surname-only-contain-letter-underscore");
      setsurnameValidate(true);
    }
    //  else {
    //   setsurnameValidate(false);
    // }
    else if (values.dob == "") {
      setdobValidate(true);
      errors.dob = t("date-of-birth-required");
    }
    //  else {
    //   setdobValidate(false);
    // }
    else if (values.address === "") {
      setaddressValidate(true);
      errors.address = t("address-required");
    } 
    // else if (values.address.length > 250) {
    //   setaddressValidate(true);
    //   errors.address = t("address-too-long");
    // }
    // else if (values.unit !== "" && (values.unit.length < 3 || values.unit.length > 16)) {
    //   setunitValidateValidate(true);
    //   errors.unit = t("unit-must-be-between-3-and-16");
    // }
    // else {
    //   setaddressValidate(false);
    // }
    else if (values.city === "") {
      setcityValidate(true);
      errors.city = t("city-required");
    } else if (!/^[a-zA-Z\s]{3,60}$/.test(values.city)) {
      setcityValidate(true);
      errors.city = t("Invalid city name!");
    }
    // else {
    //   setcityValidate(false);
    // }
    else if (values.phoneNumber == "") {
      setphoneNumberValidate(true);
      errors.phoneNumber = t("phone-number-required");
    } else if (values.phoneNumber.length < 5) {
      errors.phoneNumber = t("phone-num-should-not-below-5");
      setphoneNumberValidate(true);
    }
    // else {
    //   setphoneNumberValidate(false);
    // }
    else if (values.postalCode === "") {
      setpostalCodeValidate(true);
      errors.postalCode = t("zip/postal-required");
    }
    // else if (values.postalCode.length !== 5) {
    //   setpostalCodeValidate(true);
    //   errors.postalCode = t("zip/postal-invalid-length");
    // }
    // else {
    //   setpostalCodeValidate(false);
    // }
    else if (values.country == "") {
      setcountryValidate(true);
      errors.country = t("country-required");
    }
    //  else {
    //   setcountryValidate(false);
    // }
    else if (values.nationality == "") {
      setnationalityValidate(true);
      errors.nationality = t("nationality-required");
    }
    //  else {
    //   setnationalityValidate(false);
    // }

    //  else {
    //   setaddressProofValidate(false);
    // }
    else if (selectAssociateref.current === "yes") {
      if (values.associateFirstname === "") {
        setassociateFirstnameValidate(true);
        errors.associateFirstname = t("associate-fname-required");
      } 
      // else if (values.associateFirstname.length < 3 || values.associateFirstname.length > 25) {
      //   setassociateFirstnameValidate(true);
      //   errors.associateFirstname = t("associate-fname-invalid-length");
      // }
      // else {
      //   setassociateFirstnameValidate(false);
      // }
      else if (values.associateSurname == "") {
        setassociateSurnameValidate(true);
        errors.associateSurname = t("associate-surname-required");
      } 
      // else if (values.associateSurname.length < 3 || values.associateSurname.length > 25) {
      //   setassociateFirstnameValidate(true);
      //   errors.associateSurname = t("associate-fname-invalid-length");
      // }
      // else {
      //   setassociateSurnameValidate(false);
      // }
      else if (values.relation == "") {
        setrelationValidate(true);
        errors.relation = t("associate-relation-required");
      }
      else if (values.identityDocument == ""|| idproofref.current=="" ||idproofref.current==undefined ||idproofref.current==null) {
        setidentityDocumentValidate(true);
        errors.identityDocument = t("please-select-one-identity-doc");
        setrelationValidate(false);
        setassociateFirstnameValidate(false);
        setassociateSurnameValidate(false);
      }
      else if (values.addressProof == "" || addressProofref.current=="" ||addressProofref.current==undefined ||addressProofref.current==null) {
        setaddressProofValidate(true);
        errors.addressProof = t("please-select-one-address-proof");
      }else{
          setfirstNameValidate(false);
          setsurnameValidate(false);
          setdobValidate(false);
          setaddressValidate(false);
          setunitValidateValidate(false);
          setcityValidate(false);
          setphoneNumberValidate(false);
          setpostalCodeValidate(false);
          setcountryValidate(false);
          setidentityDocumentValidate(false);
          setnationalityValidate(false);
          setaddressProofValidate(false);
          setassociateFirstnameValidate(false);
          setassociateSurnameValidate(false);
          setrelationValidate(false);
          setrelationValidate(false);
      }
    }
     else if (values.identityDocument == ""|| idproofref.current=="" ||idproofref.current==undefined ||idproofref.current==null) {
      setidentityDocumentValidate(true);
      errors.identityDocument = t("please-select-one-identity-doc");
    } else if (
      values.addressProof == "" ||
      addressProofref.current == "" ||
      addressProofref.current == undefined ||
      addressProofref.current == null
    ) {
      setaddressProofValidate(true);
      errors.addressProof = t("please-select-one-address-proof");
    }
    
    else {
      setfirstNameValidate(false);
      setsurnameValidate(false);
      setdobValidate(false);
      setaddressValidate(false);
      setunitValidateValidate(false);
      setcityValidate(false);
      setphoneNumberValidate(false);
      setpostalCodeValidate(false);
      setcountryValidate(false);
      setidentityDocumentValidate(false);
      setnationalityValidate(false);
      setaddressProofValidate(false);
      setassociateFirstnameValidate(false);
      setassociateSurnameValidate(false);
      setrelationValidate(false);
      setrelationValidate(false);
    }

    setvalidationnErr(errors);
    return errors;
  };

  const imageUpload = (type, val) => {
    try {
      const fileExtension = val.name.split(".").at(-1);
      const fileSize = val.size;
      const fileName = val.name;
      if (
        fileExtension != "png" &&
        fileExtension != "jpg" &&
        fileExtension != "jpeg"
      ) {
        toast.error(t("file-does-not-support"));
      } else if (fileSize > 10000000) {
        toast.error(t("please-upload-file-smaller-than-1mb"));
      } else {
        type == "identityDocument"
          ? setidproofLoad(true)
          : setaddressProofLoad(true);
        const data = new FormData();
        data.append("file", val);
        data.append("upload_preset", env.upload_preset);
        data.append("cloud_name", env.cloud_name);
        fetch(
          "https://api.cloudinary.com/v1_1/" + env.cloud_name + "/auto/upload",
          { method: "post", body: data }
        )
          .then((resp) => resp.json())
          .then((data) => {
            if (type == "identityDocument") {
              setidproofLoad(false);
              setvalididproof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setvalididproof(1);
              }
              setidproof(data.secure_url);
              setidproofname(val.name);
            validate(formValue)

            }
            if (type == "addressProof") {
              setaddressProofLoad(false);
              setvalidaddressProof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setvalidaddressProof(1);
              }
              setaddressproofname(val.name);
              setaddressProof(data.secure_url);
              validate(formValue)
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error(t("please-try-again-later"));
          });
      }
    } catch (error) {
      toast.error(t("please-try-again-later"));
    }
  };

  const formSubmit = async (payload) => {
    let errros = validate(formValue);
    formValue["personAccess"] = selectPersonref.current;
    formValue["politicalPerson"] = selectAccessref.current;
    formValue["publicPosition"] = selectAssociateref.current;
    formValue["identityDocumenturl"] = idproofref.current;
    formValue["addressProofurl"] = addressProofref.current;
    formValue["verifiedSteps"] = "Step-1";
    if (
      firstNameValidateref.current === false &&
      surnameValidateref.current === false &&
      dobValidateref.current === false &&
      addressValidateref.current === false &&
      cityValidateref.current === false &&
      phoneNumberValidateref.current === false &&
      postalCodeValidateref.current === false &&
      countryValidateref.current === false &&
      nationalityValidateref.current === false &&
      identityDocumentValidateref.current == false &&
      addressProofValidateref.current == false &&
      idproofref.current != "" &&
      addressProofref.current != ""
    ) {
      var data = {
        apiUrl: apiService.kycfirst,
        payload: formValue,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      // setFormValue(initialFormValue);
      setbuttonLoader(false);
      if (resp.status == true) {
        toast.success(resp.Message);
        navigate("/KycInformation2");
      } else {
        toast.error(resp.Message);
      }
    } 
  };

  const getStep = async () => {
    try {
      var data = {
        apiUrl: apiService.get_kyc_details,
      };
      var resp = await getMethod(data);
      if (resp.data !== null) {
        var kycData = resp.data;
        formValue.firstName = kycData.firstName;
        formValue.surname = kycData.surname;
        formValue.kycStatus = kycData.kycStatus;
        formValue.dob = kycData.dateOfBirth;
        formValue.address = kycData.address;
        formValue.city = kycData.city;
        formValue.phoneNumber = kycData.phoneNumber;
        formValue.postalCode = kycData.postalCode;
        formValue.country = kycData.country;
        formValue.nationality = kycData.nationality;
        formValue.associateFirstname = kycData.associateFirstname;
        formValue.associateSurname = kycData.associateSurname;
        formValue.relation = kycData.relation;
        formValue.identityDocument = kycData.identityDocument;
        formValue.addressProof = kycData.addressProof;
        setSelectPerson(kycData.personAccess==""?"no":kycData.personAccess);
        setSelectAccess(kycData.politicalPerson=="" ?"no":kycData.politicalPerson);
        setSelectAssociate(kycData.publicPosition =="" ?"no" :kycData.publicPosition);
        if (kycData.identityDocumenturl != "") {
          setidproofLoad(false);
          setvalididproof(2);
          setidproof(kycData.identityDocumenturl);
        }
        if (kycData.addressProofurl != "") {
          setaddressProofLoad(false);
          setvalidaddressProof(2);
          setaddressProof(kycData.addressProofurl);
        }

        formValue.unit = kycData.unit=="null"?0:kycData.unit;
      }
    } catch (err) {}
  };

  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>{" "}
      <div>
        <div className="Verification assets_main kyc_page_main">
          <div className="container">
            <div className="table_padding_bottom">
              <Link to="/kyc">
                <h6>
                  {" "}
                  <i class="fa-solid fa-arrow-left-long mr-3"></i>{" "}
                  {t("verification")}
                </h6>
              </Link>
              <div className="row justify-content-center cards">
                <div className="col-lg-5 kyc_information">
                  <h3 class="heading kyc-info_title">
                    <div className="inf_no">1</div>
                    {t("basic-account-info")}
                  </h3>
                  <div className="input-groups fiat_deposit_name_details">
                    <div className="fiat_deposit_detail">
                      <h6 className="input-label">{t("first-name")}</h6>
                      <div className="input-field ref_input">
                        <input
                          type="text"
                          minLength={3}
                          maxLength={25}
                          name="firstName"
                          value={firstName}
                          onChange={handleChange}
                          className=""
                          placeholder={t("enter-your-name-here")}
                        />
                      </div>
                      {validationnErr && validationnErr.firstName && (
                        <p className="errorcss">{validationnErr.firstName}</p>
                      )}
                    </div>
                    <div className="fiat_deposit_detail">
                      <h6 className="input-label">{t("surname")}</h6>
                      <div className="input-field ref_input">
                        <input
                          type="text"
                          name="surname"
                          minLength={3}
                          maxLength={25}
                          value={surname}
                          onChange={handleChange}
                          className=""
                          placeholder={t("enter-your-surname-here")}
                        />
                      </div>
                      {validationnErr && validationnErr.surname && (
                        <p className="errorcss">{validationnErr.surname}</p>
                      )}
                    </div>
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label">{t("date-of-birth")}</h6>
                    <div className="input-field ref_input">
                      <input
                        type="date"
                        name="dob"
                        value={dob}
                        onChange={handleChange}
                        max={getTodayDate()}
                        className=""
                        placeholder="MM/DD/YYYY"
                      />
                    </div>
                    {validationnErr && validationnErr.dob && (
                      <p className="errorcss">{validationnErr.dob}</p>
                    )}
                  </div>
                  <div className="input-groups fiat_deposit_name_details">
                    <div className="fiat_deposit_detail">
                      <h6 className="input-label">{t("address")}</h6>
                      <div className="input-field ref_input">
                        <input
                          type="text"
                          name="address"
                          value={address}
                          minLength={3}
                          maxLength={250}
                          onChange={handleChange}
                          className=""
                          placeholder={t("address")}
                        />
                      </div>
                      {validationnErr && validationnErr.address && (
                        <p className="errorcss">{validationnErr.address}</p>
                      )}
                    </div>
                    <div className="fiat_deposit_detail">
                      <h6 className="input-label">{t("unit#(optional)")}</h6>
                      <div className="input-field ref_input">
                        <input
                          type="text"
                          id="numberInput"
                          min="0"
                          maxLength={16}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            ); // Allows only numbers
                          }}
                          name="unit"
                          value={unit}
                          onChange={handleChange}
                          className=""
                          placeholder="Unit #"
                        />
                      </div>
                      {validationnErr && validationnErr.unit && (
                        <p className="errorcss">{validationnErr.unit}</p>
                      )}
                    </div>
                  </div>
                  <div className="input-groups fiat_deposit_name_details">
                    <div className="fiat_deposit_detail">
                      <h6 className="input-label">{t("city")}</h6>
                      <div className="input-field ref_input">
                        <input
                          type="text"
                          name="city"
                          value={city}
                          minLength={2}
                          maxLength={50}
                          onChange={handleChange}
                          className=""
                          placeholder={t("city")}
                        />
                      </div>
                      {validationnErr && validationnErr.city && (
                        <p className="errorcss">{validationnErr.city}</p>
                      )}
                    </div>
                    <div className="fiat_deposit_detail">
                      <h6 className="input-label">{t("phone-number")}</h6>
                      {/* <div className="input-field ref_input">
                        <input
                          type="number"
                          name="OTP"
                          className=""
                          placeholder="+91"
                        />
                      </div> */}
                      <PhoneInput
                        country={"us"}
                        value={phoneNumber}
                        onChange={handlePhoneChange}
                        inputProps={{
                          name: "phoneNumber",
                          required: true,
                          className: "input-phone mb-0",
                        }}
                      />
                      {validationnErr && validationnErr.phoneNumber && (
                        <p className="errorcss">{validationnErr.phoneNumber}</p>
                      )}
                    </div>
                  </div>
                  <div className="input-groups fiat_deposit_name_details">
                    <div className="fiat_deposit_detail">
                      <h6 className="input-label">{t("zip-postal-code")}</h6>
                      <div className="input-field ref_input">
                        <input
                          type="text"
                          id="numberInput"
                          min="0"
                          maxLength={5}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            ); // Allows only numbers
                          }}
                          name="postalCode"
                          value={postalCode}
                          onChange={handleChange}
                          className=""
                          placeholder={t("enter-zip-postal-code")}
                        />
                      </div>
                      {validationnErr && validationnErr.postalCode && (
                        <p className="errorcss">{validationnErr.postalCode}</p>
                      )}
                    </div>
                    <div className="fiat_deposit_detail">
                      <h6 className="input-label">{t("country")}</h6>

                      <div className="custom-select-stake kyc-verify my-2 w-100">
                        <div className="input-groups select-wrapper ">
                          <Select
                            options={options}
                            value={options.find(
                              (option) => option.value === country
                            )}
                            onChange={handleCountryChange}
                            placeholder={t("select-country")}
                            styles={colourStyles}
                            className="reg-select-dropdown "
                          />
                        </div>
                      </div>
                      {validationnErr && validationnErr.country && (
                        <p className="errorcss">{validationnErr.country}</p>
                      )}
                    </div>
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label">{t("nationality")}</h6>
                    <div className="custom-select-stake my-2 w-100">
                      <div className="input-groups select-wrapper ">
                        <Select
                          options={options}
                          value={options.find(
                            (option) => option.value === nationality
                          )}
                          onChange={handleNationalityChange}
                          placeholder={t("select-nationality")}
                          styles={colourStyles}
                          className="reg-select-dropdown "
                        />
                      </div>
                    </div>
                    {/* <div className="input-field ref_input kyc_country_dropdown"></div> */}
                    {validationnErr && validationnErr.nationality && (
                      <p className="errorcss">{validationnErr.nationality}</p>
                    )}
                  </div>
                  <div className="access_act_text">
                    <h3>{t("will-this-person-access")}</h3>
                    <div className="access_act_text_btn">
                      <button
                        onClick={handleYesAccess}
                        className={
                          selectPersonref.current === "yes"
                            ? "yes_button"
                            : "no_button"
                        }
                      >
                        {t("yes")}
                      </button>
                      <button
                        onClick={handleNoAccess}
                        className={
                          selectPersonref.current === "no"
                            ? "yes_button"
                            : "no_button"
                        }
                      >
                        {t("no")}
                      </button>
                    </div>
                  </div>
                  <div className="access_act_text">
                    <p className="holder_content">
                      {t("is-the-account-holder")}
                    </p>
                    <p>{t("persons-who-hold")}</p>
                    <div className="access_act_text_btn">
                      <button
                        onClick={accountYes}
                        className={
                          selectAccessref.current === "yes"
                            ? "yes_button"
                            : "no_button"
                        }
                      >
                        {t("yes")}
                      </button>
                      <button
                        onClick={accountNo}
                        className={
                          selectAccessref.current === "no"
                            ? "yes_button"
                            : "no_button"
                        }
                      >
                        {t("no")}
                      </button>
                    </div>
                  </div>
                  <div className="access_act_text">
                    <p className="holder_content">
                      {t("is-the-account-holder-relative")}
                    </p>

                    <div className="access_act_text_btn">
                      <button
                        onClick={handleYesAssociate}
                        className={
                          selectAssociateref.current === "yes"
                            ? "yes_button"
                            : "no_button"
                        }
                      >
                        {t("yes")}
                      </button>
                      <button
                        onClick={handleNoAssociate}
                        className={
                          selectAssociateref.current === "no"
                            ? "yes_button"
                            : "no_button"
                        }
                      >
                        {t("no")}
                      </button>
                    </div>
                  </div>
                  {selectAssociateref.current === "yes" ? (
                    <>
                      <div className="input-groups fiat_deposit_name_details">
                        <div className="fiat_deposit_detail">
                          <h6 className="input-label">{t("first-name")}</h6>
                          <div className="input-field ref_input">
                            <input
                              type="text"
                              name="associateFirstname"
                              minLength={3}
                              maxLength={50}
                              value={associateFirstname}
                              onChange={handleChange}
                              className=""
                              placeholder={t("enter-your-name-here")}
                            />
                          </div>
                          {selectAssociateref.current === "yes" &&
                            validationnErr &&
                            validationnErr.associateFirstname && (
                              <p className="errorcss">
                                {validationnErr.associateFirstname}
                              </p>
                            )}
                        </div>
                        <div className="fiat_deposit_detail">
                          <h6 className="input-label">{t("surname")}</h6>
                          <div className="input-field ref_input">
                            <input
                              type="text"
                              name="associateSurname" 
                              minLength={3}
                              maxLength={50}
                              value={associateSurname}
                              onChange={handleChange}
                              className=""
                              placeholder={t("enter-your-surname-here")}
                            />
                          </div>
                          {selectAssociateref.current === "yes" &&
                            validationnErr &&
                            validationnErr.associateSurname && (
                              <p className="errorcss">
                                {validationnErr.associateSurname}
                              </p>
                            )}
                        </div>
                      </div>
                      <div className="input-groups icons">
                        <h6 className="input-label">
                          {t("if-yes-indicate-relationship")}
                        </h6>
                        <div className="custom-select-stake my-2 w-100">
                          <div className="input-groups select-wrapper ">
                            <Select
                              options={relationOptions}
                              value={relationOptions.find(
                                (option) => option.value === relation
                              )}
                              onChange={handleRelationChange}
                              placeholder={t("select-relationship")}
                              styles={colourStyles}
                              className="reg-select-dropdown "
                            />
                          </div>
                        </div>
                        {/* <div className="input-field ref_input upload_icon kyc_country_dropdown"></div> */}
                        {validationnErr && validationnErr.relation && (
                          <p className="errorcss">{validationnErr.relation}</p>
                        )}
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  <div className="access_act_text">
                    <h3>{t("identity-document")}</h3>
                  </div>

                  <div className="driver_license_content">
                    <input
                      id="check-comp"
                      type="checkbox"
                      // className="checkbox-round"
                      className={
                        idproofref.current == ""
                          ? "checkbox-round"
                          : "checkbox-round-checked"
                      }
                      // onClick={handleLicense}
                    />
                    {/* <label htmlFor="check-license">  Driver's license</label>
                    <i class="ri-arrow-down-s-line"></i> */}
                    <div className="input-field ref_input upload_icon kyc_country_dropdown w-200">
                      <Select
                        options={documentOptions}
                        onChange={handleDocumentChange}
                        placeholder={t("select-identity-document")}
                        value={documentOptions.find(
                          (option) => option.value === identityDocument
                        )}
                        styles={colourStyles}
                      />
                    </div>
                  </div>

                  {identityDocument != "" && (
                    <>
                      <div className="driver_license_doc">
                        <div className="upload">
                          {idproofLoad == false ? (
                            valididproof == 0 ? (
                              <>
                                <div className="inner_frst_display">
                                  <i class="ri-upload-2-line"></i>
                                  <p>{t("click-to-upload-file")}</p>
                                </div>
                              </>
                            ) : valididproof == 1 ? (
                              <i class="bi bi-file-earmark-bar-graph"></i>
                            ) : (
                              <img
                                src={idproofref.current}
                                className="up_im_past"
                                alt="National Id Front"
                              />
                            )
                          ) : (
                            <div className="inner_frst_display">
                              <i class="fa-solid fa-spinner fa-spin fa-2xl"></i>
                            </div>
                          )}

                          <input
                            type="file"
                            name="image"
                            className="image_upload_kyc"
                            onChange={(e) =>
                              imageUpload("identityDocument", e.target.files[0])
                            }
                          />
                          {idproofnameref.current == "" ? (
                            ""
                          ) : (
                            <div className="">
                              <input
                                className="proofs_name"
                                disabled
                                value={idproofnameref.current}
                              ></input>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  {validationnErr && validationnErr.identityDocument && (
                    <p className="errorcss">
                      {validationnErr.identityDocument}
                    </p>
                  )}

                  <div className="driver_license_doc">
                    <div className="access_act_text">
                      <h3>{t("proof-of-address")}</h3>
                      <p className="clr-neutral">
                        {t("Should-have-the-same-address")}
                      </p>
                    </div>
                  </div>

                  <div className="driver_license_content">
                    <input
                      id="check-proof"
                      type="checkbox"
                      className={
                        addressProofref.current == ""
                          ? "checkbox-round"
                          : "checkbox-round-checked"
                      }
                      // onClick={handleCredit}
                    />
                    {/* <label htmlFor="custom-credit"> Credit Card Statement  <i class="ri-arrow-down-s-line"></i></label> */}
                    <div className="input-field ref_input upload_icon kyc_country_dropdown w-200">
                      <Select
                        options={addressProofOptions}
                        onChange={handleAddressProofChange}
                        placeholder={t("select-address-proof")}
                        value={addressProofOptions.find(
                          (option) => option.value === addressProof
                        )}
                        className=""
                        styles={colourStyles}
                      />
                    </div>
                  </div>

                  {addressProof != "" && (
                    <div className="driver_license_doc">
                      <div className="upload">
                        {addressProofLoad == false ? (
                          validaddressProof == 0 ? (
                            <>
                              <div className="inner_frst_display">
                                <i class="ri-upload-2-line"></i>
                                <p>{t("click-to-upload-file")}</p>
                              </div>
                            </>
                          ) : validaddressProof == 1 ? (
                            <i class="bi bi-file-earmark-bar-graph"></i>
                          ) : (
                            <img
                              src={addressProofref.current}
                              className="up_im_past"
                              alt="National Id Front"
                            />
                          )
                        ) : (
                          <div className="inner_frst_display">
                            <i class="fa-solid fa-spinner fa-spin fa-2xl"></i>
                          </div>
                        )}

                        <input
                          type="file"
                          name="image"
                          className="image_upload_kyc"
                          onChange={(e) =>
                            imageUpload("addressProof", e.target.files[0])
                          }
                        />
                        {addressproofnameref.current == "" ? (
                          ""
                        ) : (
                          <div className="">
                            <input
                              className="proofs_name"
                              disabled
                              value={addressproofnameref.current}
                            ></input>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {validationnErr && validationnErr.addressProof && (
                    <p className="errorcss">{validationnErr.addressProof}</p>
                  )}
                  <div className="Submit my-4">
                    {buttonLoader == false ? (
                      <button onClick={formSubmit}>{t("next")}</button>
                    ) : (
                      <button>{t("loading")} ...</button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

/** 
 * <div className="custom-select-stake kyc-verify my-2 w-100"></div>
                    <div className="input-groups select-wrapper "></div>
                    
                    */
