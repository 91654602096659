import React, { useState, useRef, useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Landing() {
  const [offset, setOffset] = useState(0);
  const { t } = useTranslation();

  const buyChickenRef = useRef(null);
  const shellChickenRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (buyChickenRef.current) {
        const sectionTop = buyChickenRef.current.offsetTop;
        const sectionHeight = buyChickenRef.current.offsetHeight;
        const pageOffset = window.pageYOffset;

        if (
          pageOffset >= sectionTop &&
          pageOffset <= sectionTop + sectionHeight
        ) {
          setOffset(pageOffset - sectionTop);
        } else {
          setOffset(0);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [loginCheck, setloginCheck] = useState(false);

  useEffect(() => {
    let userToken = localStorage.getItem("user_token");
    if (userToken) {
      setloginCheck(true);
    } else {
      setloginCheck(false);
    }

    const handleScroll = () => {
      if (shellChickenRef.current) {
        const sectionTop = shellChickenRef.current.offsetTop;
        const sectionHeight = shellChickenRef.current.offsetHeight;
        const pageOffset = window.pageYOffset;

        if (
          pageOffset >= sectionTop &&
          pageOffset <= sectionTop + sectionHeight
        ) {
          setOffset(pageOffset - sectionTop);
        } else {
          setOffset(0);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <main className="fidex_landing_main">
        <section>
          <Header />
        </section>

        <section className="fidex_hero_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="hero_section_content">
                  <h1>{t("hero-title")}</h1>
                  <p>{t("hero-content")}</p>
                  {loginCheck == true ? (
                    <Link to="/dashboard">
                      <button className="get_started_btn">
                        {t("getstarted")}
                      </button>
                    </Link>
                  ) : (
                    <Link to="/register">
                      <button className="get_started_btn">
                        {t("getstarted")}
                      </button>
                    </Link>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="hero_section_banner">
                  <img src={require("../assets/hero_banner.webp")} />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* dive into the world */}
        <section className="secrets_crypto_section">
          <div className="container">
            <div className="secrets_crypto_content">
              <div className="secrets_crypto_title">
                <h2> {t("dive-title")} </h2>
                <p>{t("dive-content")}</p>
              </div>

              <div className="row">
                <div className="col-lg-4 bg_overflow">
                  <div className="secrets_crypto_create mar-top-2">
                    <div className="secrets_crypto_create_icon">
                      <div className="secrets-icon-wrapper">
                        <img
                          src={require("../assets/profile_icon.png")}
                          className="secrets-icon"
                        />
                      </div>
                      <h4 className="secrets_crypto-title">
                        {t("dive-card1-title")}
                      </h4>
                    </div>
                    <p>{t("dive-card1-content")}</p>
                    <div className="mar-top-create">
                      {/* <Link to=""> */}
                      <button className="lets-try-btn">
                        {t("try-it")}{" "}
                        <span className="mx-1">
                          {" "}
                          <i class="fa-solid fa-angle-right"></i>
                        </span>
                      </button>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 bg_overflow">
                  <div className="secrets_crypto_create deposit_card mar-top-2">
                    <div className="secrets_crypto_create_icon">
                      <div className="secrets-icon-wrapper">
                        <img
                          src={require("../assets/deposit_icon.png")}
                          className="secrets-icon"
                        />
                      </div>
                      <h4 className="secrets_crypto-title">
                        {" "}
                        {t("dive-card2-title")}
                      </h4>
                    </div>
                    <p>{t("dive-card2-content")}</p>
                    <div className=" mar-top-deposit">
                      {/* <Link to="/deposit"> */}
                      <button className="lets-try-btn">
                        {t("try-it")}{" "}
                        <span className="mx-1">
                          {" "}
                          <i class="fa-solid fa-angle-right"></i>
                        </span>
                      </button>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 bg_overflow">
                  <div className="secrets_crypto_create trade_card mar-top-2">
                    <div className="secrets_crypto_create_icon">
                      <div className="secrets-icon-wrapper">
                        <img
                          src={require("../assets/trade_coin.png")}
                          className="secrets-icon"
                        />
                      </div>
                      <h4 className="secrets_crypto-title">
                        {" "}
                        {t("dive-card3-title")}
                      </h4>
                    </div>
                    <p>{t("dive-card3-content")}</p>
                    <div className="mar-top-trade">
                      {/* <Link to="/trade/ETH_USDC"> */}
                      <button className="lets-try-btn">
                        {t("try-it")}{" "}
                        <span className="mx-1">
                          {" "}
                          <i class="fa-solid fa-angle-right"></i>
                        </span>
                      </button>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Core foundations*/}
        <section className="advance_trade_section">
          <div className="container">
            <div className="advance_trade_title">
              <p>{t("core-title")}</p>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="fast_tranSsaction_card">
                  <div className="fast-transImg-wrapper-one">
                    <img
                      src={require("../assets/fast_transaction_ico.webp")}
                      className="fast-trans-img"
                    />
                  </div>
                  <h4 className="fast-trans-title">{t("core-card1-title")}</h4>
                  <p>{t("core-card1-content")}</p>
                  <div>
                    {/* <Link to="/trade/ETH_USDC"> */}
                    <button className="trade-btn">
                      {t("core-trade")}
                      <i class="fa-solid fa-arrow-right"></i>
                    </button>
                    {/* </Link>{" "} */}
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="fast_tranSsaction_card">
                  <div className="fast-transImg-wrapper-two">
                    {/* <img
                      src={require("../assets/low_fees.png")}
                      className="fast-trans-img"
                    /> */}
                    <img
                      src={require("../assets/robust_icon.webp")}
                      className="fast-trans-img"
                    />
                  </div>
                  <h4 className="fast-trans-title">{t("core-card2-title")}</h4>
                  <p>{t("core-card2-content")}</p>
                  <div className="core-mt-20">
                    {/* <Link to="/trade/ETH_USDC"> */}
                    <button className="trade-btn">
                      {t("core-trade")} <i class="fa-solid fa-arrow-right "></i>
                    </button>
                    {/* </Link> */}
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="fast_tranSsaction_card">
                  <div className="fast-transImg-wrapper-three">
                    <img
                      src={require("../assets/transparency.webp")}
                      className="fast-trans-img"
                    />
                  </div>
                  <h4 className="fast-trans-title">{t("core-card3-title")}</h4>
                  <p>{t("core-card3-content")}</p>
                  <div className="core-mt-20">
                    {/* <Link to="/trade/ETH_USDC"> */}
                    <button className="trade-btn">
                      {t("core-trade")} <i class="fa-solid fa-arrow-right"></i>
                    </button>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*Crypto Asset Exchange */}
        <div className="" ref={buyChickenRef}>
          <section className="advance_trading_section">
            <div className="container">
              <div className="advance_trading_section_content">
                <div className="row">
                  <div className="col-lg-6 crypto_platform_paralax">
                    <div
                      className="parallax-text left-to-right d-flex"
                      style={{
                        transform: `translateY(${-offset * 0.5}px)`,
                      }}
                    >
                      <img src={require("../bitcoin/features_card3.webp")} />
                      <img src={require("../bitcoin/features_card2.webp")} />
                      <img src={require("../bitcoin/features_card1.webp")} />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="discover_advance_content">
                      <h4>{t("crypto-asset-title")}</h4>
                      <h2>
                        {t("crypto-asset-title2")} <br />
                        {t("crypto-asset-title3")}{" "}
                      </h2>
                      <p>{t("crypto-asset-content")}</p>
                      {loginCheck == true ? (
                        <Link to="/dashboard">
                          <button className="get_started_btn">
                            {t("getstarted")}
                          </button>
                        </Link>
                      ) : (
                        <Link to="/register">
                          <button className="get_started_btn">
                            {t("getstarted")}
                          </button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Listed Crypto  */}
          <section className="advance_trading_section">
            <div className="container">
              <div className="supported_currencies">
                <div className="row">
                  <div className="col-lg-7">
                    <div className="supported_currencies_content">
                      <h4>{t("listed-title")}</h4>
                      <h2>
                        {t("listed-title2")} <br /> {t("listed-title3")}
                      </h2>
                      <p>{t("listed-content")}</p>
                      <Link to="/register">
                        <button className="get_started_btn">
                          {" "}
                          {t("getstarted")}
                        </button>
                      </Link>
                    </div>
                  </div>

                  <div className="col-lg-5">
                    <section className="buy_chicken_section">
                      <div className="row">
                        {/* previously it had col-lg-3 - now changed for mobile view */}{" "}
                        <div className="col-3">
                          {" "}
                          <div
                            className="parallax-text left-to-right d-flex"
                            style={{
                              transform: `translateY(${-offset * 0.5}px)`,
                            }}
                          >
                            <img
                              src={require("../assets/hero-arbitrum.webp")}
                              alt="arbitrum"
                            />
                            <img
                              src={require("../assets/hero-link.webp")}
                              alt="link"
                            />
                            <img
                              src={require("../assets/hero-usdt.webp")}
                              alt="usdt"
                            />
                            <img
                              src={require("../assets/hero-trx.webp")}
                              alt="trx"
                            />
                            <img
                              src={require("../assets/hero-btc.webp")}
                              alt="btc"
                            />
                            <img
                              src={require("../assets/hero-eth.webp")}
                              alt="eth"
                            />
                            <img
                              src={require("../assets/hero-bnb.webp")}
                              alt="bnb"
                            />
                            <img
                              src={require("../assets/hero-arbitrum.webp")}
                              alt="arbitrum"
                            />
                            <img
                              src={require("../assets/hero-link.webp")}
                              alt="link"
                            />
                            <img
                              src={require("../assets/hero-usdt.webp")}
                              alt="usdt"
                            />
                            <img
                              src={require("../assets/hero-trx.webp")}
                              alt="trx"
                            />
                            <img
                              src={require("../assets/hero-eth.webp")}
                              alt="eth"
                            />
                            <img
                              src={require("../assets/hero-arbitrum.webp")}
                              alt="arbitrum"
                            />{" "}
                            <img
                              src={require("../assets/hero-btc.webp")}
                              alt="btc"
                            />
                            <img
                              src={require("../assets/hero-eth.webp")}
                              alt="eth"
                            />
                            <img
                              src={require("../assets/hero-bnb.webp")}
                              alt="bnb"
                            />
                          </div>{" "}
                        </div>
                        <div className="col-3">
                          <div
                            className="parallax-text left-to-right d-flex cener_parallax_img"
                            style={{
                              transform: `translateY(${offset * 0.5}px)`,
                            }}
                          >
                            {" "}
                            <img
                              src={require("../assets/hero-bnb.webp")}
                              alt="bnb"
                            />
                            <img
                              src={require("../assets/hero-arbitrum.webp")}
                              alt="arbitrum"
                            />
                            <img
                              src={require("../assets/hero-link.webp")}
                              alt="link"
                            />
                            <img
                              src={require("../assets/hero-usdt.webp")}
                              alt="usdt"
                            />
                            <img
                              src={require("../assets/hero-trx.webp")}
                              alt="trx"
                            />
                            <img
                              src={require("../assets/hero-btc.webp")}
                              alt="btc"
                            />
                            <img
                              src={require("../assets/hero-eth.webp")}
                              alt="eth"
                            />
                            <img
                              src={require("../assets/hero-bnb.webp")}
                              alt="bnb"
                            />
                            <img
                              src={require("../assets/hero-arbitrum.webp")}
                              alt="arbitrum"
                            />
                            <img
                              src={require("../assets/hero-link.webp")}
                              alt="link"
                            />
                            <img
                              src={require("../assets/hero-usdt.webp")}
                              alt="usdt"
                            />
                            <img
                              src={require("../assets/hero-trx.webp")}
                              alt="trx"
                            />
                            <img
                              src={require("../assets/hero-eth.webp")}
                              alt="eth"
                            />
                            <img
                              src={require("../assets/hero-arbitrum.webp")}
                              alt="arbitrum"
                            />{" "}
                            <img
                              src={require("../assets/hero-btc.webp")}
                              alt="btc"
                            />{" "}
                            <img
                              src={require("../assets/hero-eth.webp")}
                              alt="eth"
                            />
                          </div>
                        </div>
                        <div className="col-3">
                          <div
                            className="parallax-text left-to-right d-flex"
                            style={{
                              transform: `translateY(${-offset * 0.5}px)`,
                            }}
                          >
                            {" "}
                            <img
                              src={require("../assets/hero-usdt.webp")}
                              alt="usdt"
                            />
                            <img
                              src={require("../assets/hero-trx.webp")}
                              alt="trx"
                            />
                            <img
                              src={require("../assets/hero-eth.webp")}
                              alt="eth"
                            />
                            <img
                              src={require("../assets/hero-arbitrum.webp")}
                              alt="arbitrum"
                            />
                            <img
                              src={require("../assets/hero-btc.webp")}
                              alt="btc"
                            />
                            <img
                              src={require("../assets/hero-eth.webp")}
                              alt="eth"
                            />
                            <img
                              src={require("../assets/hero-bnb.webp")}
                              alt="bnb"
                            />
                            <img
                              src={require("../assets/hero-arbitrum.webp")}
                              alt="arbitrum"
                            />
                            <img
                              src={require("../assets/hero-link.webp")}
                              alt="link"
                            />
                            <img
                              src={require("../assets/hero-usdt.webp")}
                              alt="usdt"
                            />
                            <img
                              src={require("../assets/hero-trx.webp")}
                              alt="trx"
                            />
                            <img
                              src={require("../assets/hero-btc.webp")}
                              alt="btc"
                            />
                            <img
                              src={require("../assets/hero-eth.webp")}
                              alt="eth"
                            />
                            <img
                              src={require("../assets/hero-bnb.webp")}
                              alt="bnb"
                            />
                            <img
                              src={require("../assets/hero-arbitrum.webp")}
                              alt="arbitrum"
                            />
                            <img
                              src={require("../assets/hero-link.webp")}
                              alt="link"
                            />
                          </div>{" "}
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Keep Exchanging */}
          <section className="keep_trade_section">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="keep_trade_banner">
                    <div className="keep-relative">
                      <img
                        src={require("../assets/keep_trading_bannerImg.webp")}
                        className="keep_trade_mobile_banner grid-light-visible"
                      />
                      <img
                        src={require("../assets/keep_trading_bannerImg-dark.webp")}
                        className="keep_trade_mobile_banner grid-dark-visible"
                      />
                    </div>
                    {/* <div>
                      <img
                        src={require("../assets/keep_trading_bg.png")}
                        className="keep_trade_bg"
                      />
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="keep_trade_content">
                    <h2>
                      {t("keep-trading-title")} <br /> {t("keep-trading-from")}
                    </h2>
                    <p>{t("keep-trading-content")}</p>
                    <img
                      src={require("../assets/trade_qr.webp")}
                      className="trade_qr_img"
                    />
                    <div className="play_apps">
                      <Link to="">
                        <img src={require("../assets/gplay.webp")} />
                      </Link>
                      <Link to="">
                        <img src={require("../assets/ipay.webp")} />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* refer and earn */}
        <section className="refer_earn_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="refer_earn_section_content">
                  <h2>{t("landing-refer-title")}</h2>
                  <p>
                    {t("landing-refer-content")}
                    <br /> {t("landing-refer-benefit")}
                  </p>
                  <div className="bonus_btns">
                    {/* <Link to="/refferal"> */}
                    <button>{t("welcome-bonus")}</button>
                    {/* </Link> */}
                    {/* <Link to="/refferal"> */}
                    <button>{t("deposit-bonus")}</button>
                    {/* </Link> */}
                    {/* <Link to="/refferal"> */}
                    <button>{t("trading-bonus")}</button>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 refer_earn_img">
                <div className="refer_earn_banner">
                  <img src={require("../assets/refer_earn_banner.webp")} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="footer_section">
          <Footer />
        </section>
      </main>
    </>
  );
}
