import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { useTranslation } from "react-i18next";

const Terms = () => {
  const { t } = useTranslation();
  return (
    <>
      <main className="fidex_landing_main">
        <section>
          <Header />
        </section>

        <section className="fidex_hero_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="terms-container">
                  <h5 className="mb-5">{t("terms-and-conditions")}</h5>
                  <h6>{t("effective-date")}: 23.10.2024</h6>
                  <div>
                    <ol>
                      {/* Introduction */}
                      <li className="policy-sub-heading">
                        {t("introduction")}
                      </li>
                      <p>{t("welcome-to-fidex-we-by")}</p>
                      {/* definition */}
                      <li className="policy-sub-heading">
                        {" "}
                        {t("definitions")}
                      </li>
                      <ul>
                        <li>
                          <span className="policy-bold"> {t("user")}: </span>
                          {t("any-individual-or-entity")}
                        </li>
                        <li>
                          <span className="policy-bold">
                            {" "}
                            {t("services-terms")}:{" "}
                          </span>
                          {t("the-trading-and-other-financial-services")}
                        </li>
                        <li>
                          <span className="policy-bold"> {t("account")}: </span>
                          {t("the-personal-account-you-create")}
                        </li>
                      </ul>
                      {/* user eligibility */}
                      <li className="policy-sub-heading">
                        {t("user-eligibility")}
                      </li>
                      <p>{t("you-must-legally-capable")}</p>
                      {/*  Account Registration*/}{" "}
                      <li className="policy-sub-heading">
                        {t("account-registration")}
                      </li>
                      <p>{t("to-use-our-services-you-must")}</p>
                      {/*   Use of the Platform*/}{" "}
                      <li className="policy-sub-heading">
                        {t("use-of-the-platform")}
                      </li>
                      <ul>
                        <li>
                          <span className="policy-bold">
                            {t("authorized-use")}:{" "}
                          </span>
                          {t("you-agree-to-use-the-platform")}
                        </li>
                        <li>
                          <span className="policy-bold">
                            {t("prohibited-activities")}:{" "}
                          </span>
                          {t("you-may-not-engage-in-fraudulent")}
                        </li>
                      </ul>
                      {/* Trading and Transactions*/}{" "}
                      <li className="policy-sub-heading">
                        {t("trading-and-transactions")}
                      </li>{" "}
                      <ul>
                        <li>
                          <span className="policy-bold">
                            {t("execution-of-trades")}:{" "}
                          </span>
                          {t("trades-executed-through-the-platform")}
                        </li>
                        <li>
                          <span className="policy-bold">{t("fees")}: </span>
                          {t("fees-for-transactions-and-other-services")}
                        </li>
                        <li>
                          <span className="policy-bold">
                            {t("no-guarantee-of-returns")}:{" "}
                          </span>
                          {t("trading-in-cryptocurrencies-involves")}
                        </li>
                      </ul>{" "}
                      {/*  Risk Disclosure*/}{" "}
                      <li className="policy-sub-heading">
                        {t("risk-disclosure")}
                      </li>{" "}
                      <p>{t("trading-cryptocurrencies-involves")}</p>
                      {/*  Privacy and Data Protection*/}{" "}
                      <li className="policy-sub-heading">
                        {t("privacy-and-data-protection")}
                      </li>{" "}
                      <p>{t("we-are-commited-to-protecting-your-privacy")}</p>
                      {/*  Intellectual Property */}
                      <li className="policy-sub-heading">
                        {t("intellectual-property")}
                      </li>{" "}
                      <p>{t("all-content-and-materials-on-the-platform")}</p>
                      {/*  Limitation of Liability*/}
                      <li className="policy-sub-heading">
                        {t("limitation-of-liability")}
                      </li>{" "}
                      <p>{t("to-the-fullest-extent-permitted")}</p>
                      {/*  Indemnification*/}
                      <li className="policy-sub-heading">
                        {t("indemnification")}
                      </li>{" "}
                      <p>{t("you-agree-to-indemnify-and-hold")}</p>
                      {/*   Amendments*/}
                      <li className="policy-sub-heading">
                        {t("amendments")}
                      </li>{" "}
                      <p>{t("we-reserve-the-right-to-amend")}</p>
                      {/*   Termination*/}
                      <li className="policy-sub-heading">
                        {t("termination")}
                      </li>{" "}
                      <p>{t("we-may-suspend-or-terminate")}</p>
                      {/*   Governing Law*/}
                      <li className="policy-sub-heading">
                        {t("governing-law")}
                      </li>{" "}
                      <p>{t("these-terms-are-governed-by-and-construed")}</p>
                      {/*    Dispute Resolution*/}
                      <li className="policy-sub-heading">
                        {" "}
                        {t("dispute-resolution")}
                      </li>{" "}
                      <p>{t("any-disputes-arising-out-of")}</p>
                      {/*   Contact Information*/}
                      {/* <li className="policy-sub-heading">
                        {" "}
                        Contact Information
                      </li>{" "}
                      <p>
                        For any questions or concerns regarding these Terms,
                        please contact us at:
                      </p>
                      <div>
                        <span className="policy-sub-heading">Email : </span>{" "}
                        <span className="terms-email">
                          voltrixcrypt@gmail.com
                        </span>
                      </div>
                      <div className="terms-address">
                        <span className="policy-sub-heading">Address : </span>{" "}
                        <p className="policy-terms-ad">
                          FIDEX NETWORKING PRIVATE LIMITED. <br /> 45 - A, Main
                          Road, Arunachalapuram, <br /> Vickramasingapuram,{" "}
                          <br />
                          Ambasamudram, Tirunelveli- 627425, <br /> Tamil Nadu –
                          India.
                        </p>
                      </div> */}
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="footer_section">
          <Footer />
        </section>
      </main>
    </>
  );
};

export default Terms;
