import React, { useEffect } from "react";
import { TransakConfig, Transak } from '@transak/transak-sdk';
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {Trasnsac_key} from "../core/config" 
import {postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";

const Widget = ({ fromCurrency,toCurrency,email,walletAddress,userdata }) => {
console.log(fromCurrency,toCurrency,email,walletAddress,userdata,"fromCurrency,toCurrency,email,walletAddress")
  const [toastId, setToastId] = useState(null);
  const [transactionDetatils, settransactionDetatils] = useState();
  const [loader,setLoader]=useState(false)

  const showSuccessToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.success(message);
    setToastId(newToastId);
  };

  const showErrorToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.error(message);
    setToastId(newToastId);
  };


  const { t } = useTranslation();

    const openTransak =async () => {
      try{

        setLoader(true)
        const transak = new Transak({
          apiKey: Trasnsac_key.transct_key, // Replace with your Transak API key
          environment: Trasnsac_key.environment, // Or 'PRODUCTION' for live transactions
          defaultCryptoCurrency: fromCurrency, // Default cryptocurrency
          walletAddress: walletAddress, // Optional: User's crypto wallet address
          themeColor: '#33c2b9', // Customize theme color
          fiatCurrency: toCurrency, // Default fiat currency
          email: userdata.email, // Optional: Pre-fill user's email
          redirectURL: window.href, // Optional: Redirect after successful transaction
          userData: {
            firstName:userdata.firstName,
            lastName: userdata.lastName,
            email: userdata.email,
            mobileNumber: userdata.mobileNumber,
            dob: userdata.dob, // Date of birth in YYYY-MM-DD format
            address: {
            addressLine1: userdata.address,
            addressLine2: userdata.addressLine1,
            city: userdata.city,
            // state: 'CA',
            postCode: userdata.postCode,
            // countryCode: 'US', // Predefined country
            },
          },
          isKycEditable: false, 


          disableWalletAddressForm: true, // Prevent editing the wallet address
          disableFiatCurrency: true, // Prevent changing fiat currency
          disableCryptoCurrency: true, // Prevent changing cryptocurrency
          disableEmail: true, // Prevent changing the email

        });
        transak.init();

               
         // To get all the events
         Transak.on('*', (data) => {
         });
         
         // This will trigger when the user closed the widget
         Transak.on(Transak.EVENTS.TRANSAK_WIDGET_CLOSE, () => {
         });
         
         /*
         * This will trigger when the user has confirmed the order
         * This doesn't guarantee that payment has completed in all scenarios
         * If you want to close/navigate away, use the TRANSAK_ORDER_SUCCESSFUL event
         */
         Transak.on(Transak.EVENTS.TRANSAK_ORDER_CREATED, (orderData) => {
         });
         
         /*
         * This will trigger when the user marks payment is made
         * You can close/navigate away at this event
         */
         Transak.on(Transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL,async  (orderData) => {
          //  showSuccessToast(orderData.eventName=="TRANSAK_ORDER_SUCCESSFUL"?"Transaction Successfull":"")
           if (orderData.eventName=="TRANSAK_ORDER_SUCCESSFUL"){
              transak.close();
              settransactionDetatils(orderData.status);
              var Order_obj= {
                ipAddress:orderData.status.ipAddress,
                TransationId:orderData.status.TransationId,
                TransationHash:orderData.status.TransationHash,
                walletAddress:orderData.status.walletAddress,
                orderId:orderData.status.id,
                fiatCurrency:orderData.status.fiatCurrency,
                cryptoCurrency:orderData.status.cryptoCurrency,
                type:orderData.status.type,
                fiatAmount:orderData.status.fiatAmount,
                amountPaid:orderData.status.amountPaid,
                paymentOptionId:orderData.status.paymentOptionId,
                cryptoAmount:orderData.status.cryptoAmount,
                email:email
              }
              
              var data1 = {
                apiUrl: apiService.saveTransactionDetails,
                payload:Order_obj
              };
              var resp = await postMethod(data1);
              if (resp.status == true){
                setLoader(false);
                showSuccessToast(resp.Message);
              }else{
                setLoader(false);
                showErrorToast(resp.Message);
              }
            }else{
              transak.close();
              setLoader(false);
            }
         });
      }catch(err){
   console.log(err,"=-=-")
      }
               };
             
      return (
        <>
          {loader ==true ?
          <div >{t("loading")}...</div>
          :
          <div onClick={openTransak}>{t("buy-with-card")}</div>
          }
        </>
      );
    };
export default Widget;
