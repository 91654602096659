import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  const [loginCheck, setloginCheck] = useState(false);

  useEffect(() => {
    let userToken = localStorage.getItem("user_token");
    if (userToken) {
      setloginCheck(true);
    } else {
      setloginCheck(false);
    }
  }, []);

  return (
    <footer className="footer_content_section">
      <div className="container bor-bottom">
        <div className="row">
          <div className="col-lg-6">
            <div className="footer_content">
              <img
                src={require("../assets/footer_logo.webp")}
                className="footer_light_logo"
              />
              <img
                src={require("../assets/fidex_dark_logo.webp")}
                className="footer_logo_dark"
              />
              <p>{t("footer-content")}</p>
            </div>
          </div>

          <div className="col-lg-6 services_content">
            <div className="services">
              <h3>{t("services")}</h3>
              <li>
                <Link to="/staking">{t("staking")}</Link>
              </li>
              {loginCheck == true ? (
                <>
                  <li>
                    <Link to="/deposit">{t("deposit")}</Link>
                  </li>
                  <li>
                    <Link to="/Withdraw">{t("withdraw")}</Link>
                  </li>
                </>
              ) : (
                ""
              )}

              <li>
                <Link to="/buycrypto">{t("buycrpto")}</Link>
              </li>
              <li>
                <Link to="/trade/ETH_USDC">{t("trade")}</Link>
              </li>
              <li>
                <Link to="/swap">{t("convert")}</Link>
              </li>
            </div>
            <div className="services">
              <h3>{t("company")}</h3>
              <li>
                <Link to="">{t("about-us")}</Link>
              </li>
              <li>
                <Link to="">{t("contact-us")}</Link>
              </li>
              {/* <li>
                <a href="">{t("work-at-fidex")}</a>
              </li> */}
            </div>
            <div className="services">
              <h3>{t("support")}</h3>
              <li>
                <Link to="/support">{t("support")}</Link>
              </li>
              <li>
                <Link to="/privacy" target="_blank">
                  {t("privacy-policy")}
                </Link>
              </li>
              {/* <li>
                <a href="">{t("cookies-policy")}</a>
              </li> */}
              <li>
                <Link to="/terms" target="_blank">
                  {t("terms")}
                </Link>
              </li>
              {/* <li>
                <a href="">{t("complaint-channel")}</a>
              </li> */}
            </div>
            <div className="services">
              <h3>{t("socials")}</h3>
              <Link>
                <img src={require("../assets/twitter x.webp")} />
              </Link>
              <Link>
                <img src={require("../assets/Facebook.webp")} />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="copyrights">&copy; {t("copyrights")}</div>
    </footer>
  );
};

export default Footer;
