import React, { useEffect } from "react";
import useState from "react-usestateref";
import Kyb_information1 from "./Kyb_information1";
import Kyb_information2 from "./Kyb_information2";
import Kyb_information3 from "./Kyb_information3";
import Kyb_information4 from "./Kyb_information4";
import Kyb_information5 from "./Kyb_information5";
import Kyb_information6 from "./Kyb_information6";
import Kyb from "./kyb";
import { getMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Bars } from "react-loader-spinner";

const KybProcess = () => {
  //   const getSavedProgress = () => {
  //     const savedStep = localStorage.getItem('currentStep');
  //     return savedStep ? parseInt(savedStep, 10) : 1; // Default to step 1 if nothing is saved
  //   };

  const [currentStep, setCurrentStep, currentStepref] = useState("Step-1");
  const [loader, setloader] = useState(false);

  useEffect(() => {
    getStep();
    // localStorage.setItem('currentStep', currentStep);
  }, []);

  const getStep = async () => {
    try {
      setloader(true);
      var data = {
        apiUrl: apiService.get_kyc_details,
      };
      var resp = await getMethod(data);
      setloader(false);
      setCurrentStep(resp.data === null ? "" : resp.data.verifiedSteps);
    } catch (err) {}
  };

  const renderStep = () => {
    switch (currentStepref.current) {
      case "":
        return <Kyb_information1 onNext={() => setCurrentStep("Step-1")} />;
      case "Step-1":
        return <Kyb_information2 onNext={() => setCurrentStep("Step-2")} />;
      case "Step-2":
        return <Kyb_information3 onNext={() => setCurrentStep("Step-3")} />;
      case "Step-3":
        return <Kyb_information4 onNext={() => setCurrentStep("Step-4")} />;
      case "Step-4":
        return <Kyb_information5 onNext={() => setCurrentStep("Step-5")} />;
      case "Step-5":
        return <Kyb_information6 onNext={() => setCurrentStep("Step-6")} />;
      case "Step-6":
        return <Kyb onNext={() => setCurrentStep("Step-6")} />;
      default:
        return <Kyb_information1 onNext={() => setCurrentStep("Step-1")} />;
    }
  };

  return (
    <>
     {loader == true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#33c2b9"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <div>{renderStep()}</div>
      )}
      </>
  )
};

export default KybProcess;
