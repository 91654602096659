import React, { useEffect, useMemo } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import { postMethod, getMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";
import Select from "react-select";
import countryList from "react-select-country-list";
import { useTranslation } from "react-i18next";

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    // console.log({ data, isDisabled, isFocused, isSelected });
    return {
      ...styles,
      backgroundColor: isFocused ? "#33c2b9" : null,
      color: isFocused ? "#fff" : "#000",
      cursor: isDisabled ? "not-allowed" : "pointer",
    };
  },
};

export default function FiatDeposit() {
  const [OTP, setOTP] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [isInternationalTransfersSelected, setInternationalTransfersSelected] = useState(false);
  const [counter, setCounter] = useState(60);
  const [isResendVisible, setIsResendVisible] = useState(false);
  const [value, setValue] = useState("");
  const [isShowDrop, setIsShowDrop] = useState(false);
  const [isShowOther, setIsShowOther] = useState(false);
  const options = useMemo(() => countryList().getData(), []);
  const { t } = useTranslation();
  const [inflowTransactionError, setInflowTransactionError] = useState("");
  const [outflowTransactionError, setOutflowTransactionError] = useState("");
  const [countryError, setCountryError] = useState("");

  const changeHandler = (value) => {
    setValue(value);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsResendVisible(true);
    }
  }, [counter]);

  const handleDrop = () => {
    setIsShowDrop((prevDrop) => !prevDrop);
  };

  const handleOther = () => {
    setIsShowOther((prevDrop) => !prevDrop);
  };
  // AUTHOR OF JOTHI

  const fidexrelationship = [
    { label: t("savings"), value: "Savings" },
    { label: t("financing"), value: "Financing" },
    { label: t("investment"), value: "Investment" },
    { label: t("others"), value: "Others" },
  ];
  const fidexProductList = [
    { label: t("spot"), value: "Spot" },
    { label: t("staking"), value: "Staking" },
    { label: t("lending"), value: "Lending" },
  ];

  const [Frequency_inflow, setFrequency_inflow] = useState([
    { label: t("at-account-opening"), value: "At account opening" },
    { label: t("quarterly"), value: "Quarterly" },
    { label: t("random/undefined"), value: "Random/Undefined" },
    { label: t("annually"), value: "Annually" },
    { label: t("semi-annually"), value: "Semi-annually" },
  ]);

  const [inflowsFidexdata, setinflowsFidexdata] = useState([
    { label: t("Less than EUR 300,000"), value: "Less than EUR 300,000" },
    { label: t("Between EUR 300,000 and 500,000"), value: "Between EUR 300,000 and 500,000"  },
    {
      label: t("Between EUR 500,000 and 1,500,000"),
      value: "Between EUR 500,000 and 1,500,000",
    },
    {
      label: t("Between EUR 1,500,000 and 3,000,000"),
      value: "Between EUR 1,500,000 and 3,000,000",
    },
    {
      label: t("Between EUR 3,000,000 and 6,000,000"),
      value: "Between EUR 3,000,000 and 6,000,000",
    },
    { label: t("More than EUR 6,000,000"), value: "More than EUR 6,000,000" },
  ]);

  const [outflowsFidexdata, setoutflowsFidexdata] = useState([
    { label: t("Less than EUR 300,000"), value: "Less than EUR 300,000" },
    {
      label: t("Between EUR 300,000 and 500,000"),
      value: "Between EUR 300,000 and 500,000",
    },
    {
      label: t("Between EUR 500,000 and 1,500,000"),
      value: "Between EUR 500,000 and 1,500,000",
    },
    {
      label: t("Between EUR 1,500,000 and 3,000,000"),
      value: "Between EUR 1,500,000 and 3,000,000",
    },
    {
      label: t("Between EUR 3,000,000 and 6,000,000"),
      value: "Between EUR 3,000,000 and 6,000,000",
    },
    { label: t("More than EUR 6,000,000"), value: "More than EUR 6,000,000" },
  ]);

  const [inflowtransaction, setinflowtransaction] = useState([
    { label: t("domestic-transfers"), value: "Domestic Transfers" },
    { label: t("international-transfers"), value: "International Transfers" },
    { label: t("bank-card"), value: "Bank Card" },
    { label: t("direct-debits"), value: "Direct debits" },
    { label: t("internal-operations"), value: "Internal Operations" },
  ]);

  const [outflowtransaction, setoutflowtransaction] = useState([
    { label: t("domestic-transfers"), value: "Domestic Transfers" },
    { label: t("international-transfers"), value: "International Transfers" },
    { label: t("internal-operations"), value: "Internal operations" },
  ]);


  const initialFormValue = {
    relation: "",
    otherrelations: "",
    inflowTransactionfidex: [],
    outflowTransactionfidex: [],
    InternationalTransfers: "",
    fidexProducts: [],
    outInternationalTransfers: "",
    countryofbank: "",
    inflowsFidex: "",
    outflowsFidex: "",
    Frequency_inflowdata: "",
    specifyInflow: "", // Add this
    specifyOutflow: "" // Add this
  };


  const [formValue, setFormValue,formValueref] = useState(initialFormValue);
  const [ShowOtherRelationInput, setShowOtherRelationInput,ShowOtherRelationInputref] = useState(false);
  const [otherRelation, setOtherRelation] = useState("");
  const [validationnErr, setvalidationnErr] = useState("");
  const [relationValidate, setrelationValidate, relationValidateref] =
    useState(false);
  const [relationErrMsg, setrelationErrMsg, relationErrMsgref] = useState("");
  const [
    fidexProductsValidate,
    setfidexProductsValidate,
    fidexProductsValidateref,
  ] = useState(false);
  const [fidexProductsErrMsg, setfidexProductsErrMsg, fidexProductsErrMsgref] =
    useState("");
  const [
    Frequency_inflowdataValidate,
    setFrequency_inflowdataValidate,
    Frequency_inflowdataValidateref,
  ] = useState(false);
  const [
    Frequency_inflowdataErrMsg,
    setFrequency_inflowdataErrMsg,
    Frequency_inflowdataErrMsgref,
  ] = useState("");
  const [
    inflowTransactionfidexValidate,
    setinflowTransactionfidexValidate,
    inflowTransactionfidexValidateref,
  ] = useState(false);
  const [
    inflowTransactionfidexErrMsg,
    setinflowTransactionfidexErrMsg,
    inflowTransactionfidexErrMsgref,
  ] = useState("");
  const [
    outflowTransactionfidexValidate,
    setoutflowTransactionfidexValidate,
    outflowTransactionfidexValidateref,
  ] = useState(false);
  const [
    outflowTransactionfidexErrMsg,
    setoutflowTransactionfidexErrMsg,
    outflowTransactionfidexErrMsgref,
  ] = useState("");
  const [
    outflowsFidexValidate,
    setoutflowsFidexValidate,
    outflowsFidexValidateref,
  ] = useState(false);
  const [outflowsFidexErrMsg, setoutflowsFidexErrMsg, outflowsFidexErrMsgref] =
    useState("");
  const [
    inflowsFidexValidate,
    setinflowsFidexValidate,
    inflowsFidexValidateref,
  ] = useState(false);
  const [inflowsFidexErrMsg, setinflowsFidexErrMsg, inflowsFidexErrMsgref] =
    useState("");
  const [countryValidate, setcountryValidate, countryValidateref] = useState();
  const [countryErrMsg, setcountryErrMsg, countryErrMsgref] = useState();

  useEffect(() => {
    getStep();
  }, [0]);

  const handlfidexrelationshipChange = (selectedRelation) => {
    if (selectedRelation.value === "Others") {
      setShowOtherRelationInput(true);
      setFormValue({
        ...formValue,
        relation: "Others",
      });
      formValue.otherrelations=""
      setOtherRelation("")
      validate(formValueref.current);
    } else {
      setShowOtherRelationInput(false);
      const updatedFormValue = {
        ...formValue,
        relation: selectedRelation.value,
      };
      formValue.otherrelations=""
      setFormValue(updatedFormValue);
      setOtherRelation("")
    validate(formValueref.current)
    }

  };

  const handleKeyDown = (e) => {
    if (e.key === ' ' && e.target.selectionStart === 0) {
      e.preventDefault();
      }
  };


  const handleOtherRelationChange = (e) => {
    setOtherRelation(e.target.value);
    const updatedFormValue = {
      ...formValue,
      otherrelations: e.target.value,
    };
    setFormValue(updatedFormValue);
    validate(formValueref.current)
  };

  const handleProductChange = (event) => {
    const { value, checked } = event.target;
    let updatedfidexProducts;

    if (checked) {
      updatedfidexProducts = [...formValue.fidexProducts, value];
    } else {
      updatedfidexProducts = formValue.fidexProducts.filter(
        (item) => item !== value
      );
    }

    const updatedFormValue = {
      ...formValue,
      fidexProducts: updatedfidexProducts,
    };
    setFormValue(updatedFormValue);
    validate(formValueref.current);
  };

  const handlefrequencyinflowincomeChange = (selectedOption) => {
    const updatedFormValue = {
      ...formValue,
      Frequency_inflowdata: selectedOption.value,
    };
    setFormValue(updatedFormValue);
    validate(formValueref.current)

  };

  const handlInternationalTransfersChange = (event) => {
    const updatedFormValue = {
      ...formValue,
      InternationalTransfers: event.target.value,
    };
    setFormValue(updatedFormValue);
    // validate(formValueref.current);
  };

  const handloutInternationalTransfersChange = (event) => {
    const updatedFormValue = {
      ...formValue,
      outInternationalTransfers: event.target.value,
    };
    setFormValue(updatedFormValue);
    // validate(formValueref.current);
  };

  const handleCountryChange = (selectedCountry) => {
    const updatedFormValue = {
      ...formValue,
      countryofbank: selectedCountry.value,
    };
    setFormValue(updatedFormValue);
    validate(formValueref.current)
    
  };

  const handleinflowTransactionChange = (event) => {
    const { value, checked } = event.target;
    let updatedTransaction;

    if (checked) {
      updatedTransaction = [...formValue.inflowTransactionfidex, value];
    } else {
      updatedTransaction = formValue.inflowTransactionfidex.filter((item) => item !== value);
    }

    const updatedFormValue = {
      ...formValue,
      inflowTransactionfidex: updatedTransaction,
    };
    setFormValue(updatedFormValue);

    // Check if International Transfers is selected
    const isInternationalSelected = updatedTransaction.includes("International Transfers");
    setInternationalTransfersSelected(isInternationalSelected);

    // Validate inflow transactions after updating
    validate(formValueref.current)

  };

  const handleoutflowTransactionChange = (event) => {
    const { value, checked } = event.target;
    let updateouttransaction;

    if (checked) {
      updateouttransaction = [...formValue.outflowTransactionfidex, value];
    } else {
      updateouttransaction = formValue.outflowTransactionfidex.filter(
        (item) => item !== value
      );
    }

    const updatedFormValue = {
      ...formValue,
      outflowTransactionfidex: updateouttransaction,
    };
    setFormValue(updatedFormValue);

    // Validate outflow transactions after updating
    validate(formValueref.current)

  };
  // Helper function to count words
  const countWords = (str) => {
    return str.trim().split(/\s+/).length;
  };

  // Handling inflow income change with word and character limit validation
  const handleinflowincomeChange = (e) => {;
    const value = e.value;

    if (countWords(value) < 3) {
      setinflowTransactionfidexValidate(true);
      setinflowTransactionfidexErrMsg(t("inflow-min-words-error")); // Set an appropriate message
    } else if (value.length > 100) {
      setinflowTransactionfidexValidate(true);
      setinflowTransactionfidexErrMsg(t("inflow-max-characters-error")); // Set an appropriate message
    } else {
      setinflowTransactionfidexValidate(false); // Clear the error if input is valid
      const updatedFormValue = {
        ...formValue,
        inflowsFidex: value, // Set the input value if it's valid
      };
      setFormValue(updatedFormValue);
      validate(formValueref.current)

    }
  };

  // Handling outflow income change with word and character limit validation
  const handleoutflowincomeChange = (e) => {
    const value = e.value;

    if (countWords(value) < 3) {
      setoutflowTransactionfidexValidate(true);
      setoutflowTransactionfidexErrMsg(t("outflow-min-words-error")); // Set an appropriate message
    } else if (value.length > 100) {
      setoutflowTransactionfidexValidate(true);
      setoutflowTransactionfidexErrMsg(t("outflow-max-characters-error")); // Set an appropriate message
    } else {
      setoutflowTransactionfidexValidate(false); // Clear the error if input is valid
      const updatedFormValue = {
        ...formValue,
        outflowsFidex: value, // Set the input value if it's valid
      };
      setFormValue(updatedFormValue);
      validate(formValueref.current)

    }
  };

  const handleSpecifyInflowChange = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9\s]*$/; // Alphanumeric and spaces

    if (regex.test(value)) {
      const updatedFormValue = {
        ...formValue,
        specifyInflow: value,
      };
      setFormValue(updatedFormValue);
      validate(formValueref.current)

    } else {
      // Optionally, you can show an error message or ignore the input
      console.log("Invalid character detected");
    }
  };

  const handleSpecifyOutflowChange = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9\s]*$/; // Alphanumeric and spaces

    if (regex.test(value)) {
      const updatedFormValue = {
        ...formValue,
        specifyOutflow: value,
      };
      setFormValue(updatedFormValue);
      validate(formValueref.current)
    } else {
      // Optionally, you can show an error message or ignore the input
      console.log("Invalid character detected");
    }
  };

//   const validate = (formValue) => {
//     let isValid = true;

//     // Reset validation flags
//     // setrelationValidate(false);
//     setfidexProductsValidate(false);
//     setinflowTransactionfidexValidate(false);
//     setoutflowTransactionfidexValidate(false);
//     setinflowsFidexValidate(false);
//     setoutflowsFidexValidate(false);
//     setcountryValidate(false);
// console.log(formValue,"formValue")
//     const alphanumericRegex = /^[a-zA-Z0-9\s]*$/;

//     // Check if inflow transactions are selected
//       if (formValue.relation == null || formValue.relation == "" || formValue.relation == undefined || formValue.relation == null) {
//       setrelationValidate(true);
//       setrelationErrMsg(t("purpose-of-relationship-required"));
//       isValid = false;
//     } else if((ShowOtherRelationInput == true && (formValue.otherrelations  == "" || formValue.otherrelations  == undefined || formValue.otherrelations  == null))){
//       console.log("call validation here")
//       setrelationValidate(true);
//       setrelationErrMsg(t("Purpose-of-other-relationship-required"));
//       isValid = false;
//     }
//     else if (formValue.inflowTransactionfidex.length === 0) {
//       setinflowTransactionfidexValidate(true);
//       setrelationValidate(false);
//       setinflowTransactionfidexErrMsg(t("inflow-transaction-required"));
//       isValid = false;
//     } else if (formValue.inflowTransactionfidex.includes("International Transfers") && !formValue.specifyInflow) {
//       setinflowTransactionfidexValidate(true);
//       setinflowTransactionfidexErrMsg(t("please-specify-inflow"));
//       isValid = false;
//     } else if (formValue.inflowTransactionfidex.includes("International Transfers") && formValue.specifyInflow.length < 5 || !alphanumericRegex.test(formValue.specifyInflow)) {
//       setinflowTransactionfidexValidate(true);
//       setinflowTransactionfidexErrMsg(t("inflow-invalid-length-or-characters"));
//       isValid = false;
//     } else  if (formValue.outflowTransactionfidex.length === 0) {
//       setoutflowTransactionfidexValidate(true);
//       setoutflowTransactionfidexErrMsg(t("outflow-transaction-required"));
//       setinflowTransactionfidexValidate(false);
//       isValid = false;
//     } else if (formValue.outflowTransactionfidex.includes("International Transfers") && !formValue.specifyOutflow) {
//       setoutflowTransactionfidexValidate(true);
//       setoutflowTransactionfidexErrMsg(t("please-specify-outflow"));
//       isValid = false;
//     } else if (formValue.outflowTransactionfidex.includes("International Transfers") && formValue.specifyOutflow.length < 5 || !alphanumericRegex.test(formValue.specifyOutflow)) {
//       setoutflowTransactionfidexValidate(true);
//       setoutflowTransactionfidexErrMsg(t("outflow-invalid-length-or-characters"));
//       isValid = false;
//     }  else if (!formValue.fidexProducts.length) {
//       setfidexProductsValidate(true);
//       setfidexProductsErrMsg(t("fidex-products-required"));
//       isValid = false;
//     } else   if (!formValue.Frequency_inflowdata) {
//       setFrequency_inflowdataValidate(true);
//       setfidexProductsValidate(false);
//       setFrequency_inflowdataErrMsg(t("frequency-inflow-required"));
//       isValid = false;
//     } else   if (!formValue.outflowsFidex) {
//       setFrequency_inflowdataValidate(false);
//       setoutflowsFidexValidate(true);
//       setoutflowsFidexErrMsg(t("annual-volume-outflow-required"));
//       isValid = false;
//     } else if (!formValue.inflowsFidex) {
//       setoutflowsFidexValidate(false);
//       setinflowsFidexValidate(true);
//       setinflowsFidexErrMsg(t("annual-volume-inflow-required"));
//       isValid = false;
//     } else if (!formValue.countryofbank) {
//       setinflowsFidexValidate(false);
//       setcountryValidate(true);
//       setcountryErrMsg(t("country-required"));
//       isValid = false;
//     } else {
//       setcountryValidate(false);
//       setcountryErrMsg("");
//       setinflowTransactionfidexValidate(false);
//       setoutflowTransactionfidexValidate(false);
//       setrelationValidate(false);
//       setfidexProductsValidate(false);
//       setFrequency_inflowdataValidate(false);
//       setoutflowsFidexValidate(false);
//       setinflowsFidexValidate(false);
//       setrelationErrMsg("")
//       setinflowTransactionfidexErrMsg("")
//       setoutflowTransactionfidexErrMsg("")
//       setfidexProductsErrMsg("")
//       setFrequency_inflowdataErrMsg("")
//       setoutflowsFidexErrMsg("")
//       setinflowsFidexErrMsg("")

//     }

//     return isValid; // Return overall validity state
//   };


const validate = (formValue) => {
  let isValid = true;

  // Reset all validation flags and error messages
  setrelationValidate(false);
  setfidexProductsValidate(false);
  setinflowTransactionfidexValidate(false);
  setoutflowTransactionfidexValidate(false);
  setinflowsFidexValidate(false);
  setoutflowsFidexValidate(false);
  setcountryValidate(false);

  // Regular expression for alphanumeric characters with spaces
  const alphanumericRegex = /^[a-zA-Z0-9\s]*$/;

  console.log(formValue, "formValue");

  // Purpose of relationship validation
  if (
    !formValue.relation ||
    formValue.relation === "" ||
    formValue.relation == null
  ) {
    setrelationValidate(true);
    setrelationErrMsg(t("purpose-of-relationship-required"));
    isValid = false;
  } 
 if (
    formValue.relation ==="Others" && 
    (!otherRelation ||
      otherRelation === "" ||
      otherRelation === undefined ||
      otherRelation == null)
  ) {
    setrelationValidate(true);
    setrelationErrMsg(t("purpose-of-other-relationship-required"));
    isValid = false;
  }

   // Fidex products validation
   if (isValid && (!formValue.fidexProducts || !formValue.fidexProducts.length)) {
    setfidexProductsValidate(true);
    setfidexProductsErrMsg(t("fidex-products-required"));
    isValid = false;
  }

    // Annual volume of inflows validation
    if (isValid && !formValue.inflowsFidex) {
      setoutflowsFidexValidate(false);
      setinflowsFidexValidate(true);
      setinflowsFidexErrMsg(t("annual-volume-inflow-required"));
      isValid = false;
    }
  


  // Frequency of inflow data validation
  if (isValid && !formValue.Frequency_inflowdata) {
    setFrequency_inflowdataValidate(true);
    setfidexProductsValidate(false);
    setFrequency_inflowdataErrMsg(t("frequency-inflow-required"));
    isValid = false;
  }

  
  
    // Annual volume of outflows validation
    if (isValid && !formValue.outflowsFidex) {
      setFrequency_inflowdataValidate(false);
      setoutflowsFidexValidate(true);
      setoutflowsFidexErrMsg(t("annual-volume-outflow-required"));
      isValid = false;
    }

  // Inflow transaction validation
  if (isValid && formValue.inflowTransactionfidex.length === 0) {
    setinflowTransactionfidexValidate(true);
    setrelationValidate(false);
    setinflowTransactionfidexErrMsg(t("inflow-transaction-required"));
    isValid = false;
  } else if (
    isValid &&
    formValue.inflowTransactionfidex.includes("International Transfers") &&
    !formValue.specifyInflow
  ) {
    setinflowTransactionfidexValidate(true);
    setinflowTransactionfidexErrMsg(t("please-specify-inflow"));
    isValid = false;
  } else if (
    isValid &&
    formValue.inflowTransactionfidex.includes("International Transfers") &&
    (formValue.specifyInflow.length < 5 ||
      !alphanumericRegex.test(formValue.specifyInflow))
  ) {
    setinflowTransactionfidexValidate(true);
    setinflowTransactionfidexErrMsg(t("inflow-invalid-length-or-characters"));
    isValid = false;
  }

  // Outflow transaction validation
  if (isValid && formValue.outflowTransactionfidex.length === 0) {
    setoutflowTransactionfidexValidate(true);
    setoutflowTransactionfidexErrMsg(t("outflow-transaction-required"));
    setinflowTransactionfidexValidate(false);
    isValid = false;
  } else if (
    isValid &&
    formValue.outflowTransactionfidex.includes("International Transfers") &&
    !formValue.specifyOutflow
  ) {
    setoutflowTransactionfidexValidate(true);
    setoutflowTransactionfidexErrMsg(t("please-specify-outflow"));
    isValid = false;
  } else if (
    isValid &&
    formValue.outflowTransactionfidex.includes("International Transfers") &&
    (formValue.specifyOutflow.length < 5 ||
      !alphanumericRegex.test(formValue.specifyOutflow))
  ) {
    setoutflowTransactionfidexValidate(true);
    setoutflowTransactionfidexErrMsg(t("outflow-invalid-length-or-characters"));
    isValid = false;
  }


  // Country of bank validation
  if (isValid && !formValue.countryofbank) {
    setinflowsFidexValidate(false);
    setcountryValidate(true);
    setcountryErrMsg(t("country-required"));
    isValid = false;
  }

  // Clear all error messages if the form is valid
  if (isValid) {
    setrelationValidate(false);
    setinflowTransactionfidexValidate(false);
    setoutflowTransactionfidexValidate(false);
    setfidexProductsValidate(false);
    setFrequency_inflowdataValidate(false);
    setoutflowsFidexValidate(false);
    setinflowsFidexValidate(false);
    setcountryValidate(false);
    setrelationErrMsg("");
    setinflowTransactionfidexErrMsg("");
    setoutflowTransactionfidexErrMsg("");
    setfidexProductsErrMsg("");
    setFrequency_inflowdataErrMsg("");
    setoutflowsFidexErrMsg("");
    setinflowsFidexErrMsg("");
    setcountryErrMsg("");
  }

  return isValid; // Return the overall validity state
};


  const submit = async (e) => {
    e.preventDefault();
    const isValid = validate(formValue); // Ensure this returns true only when all fields are valid

    if (!isValid) {
      console.log("Validation failed, check the inputs.");
      return; // Prevent navigation to the next page if validation fails
    }
    formValue["verifiedSteps"] = "Step-4";

    // Proceed to submit data if valid
    const data = {
      apiUrl: apiService.kybfourth,
      payload: formValue,
    };

    setbuttonLoader(true); // Show loading spinner
    const resp = await postMethod(data);
    setbuttonLoader(false); // Hide loading spinner

    // Check the response and navigate accordingly
    if (resp.status) {
      toast.success(resp.Message);
      navigate("/KybInformation5"); // Only navigate if submission was successful
    } else {
      toast.error(resp.Message);
    }
  };


  const getStep = async () => {
    try {
      var data = {
        apiUrl: apiService.get_kyb_details,
      };
      var resp = await getMethod(data);
      if (resp.data !== null) {
        var kybData = resp.data;
        console.log(kybData, "kybData");
        formValue.relation = kybData.relation;
        setOtherRelation(kybData.otherrelations);
        if(kybData.relation=="Others"){setShowOtherRelationInput(true)};
        formValue.fidexProducts = kybData.fidexProducts;
        formValue.inflowTransactionfidex = kybData.inflowTransactionfidex;
        formValue.outflowTransactionfidex = kybData.outflowTransactionfidex;
        formValue.InternationalTransfers = kybData.InternationalTransfers;
        formValue.outInternationalTransfers = kybData.outInternationalTransfers;
        formValue.countryofbank = kybData.countryofbank;
        formValue.inflowsFidex = kybData.inflowsFidex;
        formValue.outflowsFidex = kybData.outflowsFidex;
        formValue.Frequency_inflowdata = kybData.Frequency_inflowdata;
        formValue.specifyInflow = kybData.specifyInflow
        formValue.specifyOutflow = kybData.specifyOutflow
      }
    } catch (err) {
      console.log(err, "=-=-=-==");
    }
  };

  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>{" "}
      <div>
        <div className="Verification assets_main kyc_page_main">
          <div className="container">
            <div className="table_padding_bottom">
              <Link to="/KybInformation3">
                <h6>
                  {" "}
                  <i class="fa-solid fa-arrow-left-long mr-3"></i>{" "}
                  {t("verification")}
                </h6>
              </Link>

              <div className="row justify-content-center cards">
                <div className="col-lg-5 kyc_information">
                  <h3 class="heading">
                    <span className="inf_no">4</span>
                    {t("account-activity")}
                  </h3>
                  <div className="input-groups icons">
                    <h6 className="input-label">
                      {t("purpose-of-relationship-fidex")}
                    </h6>
                    <div className="custom-select-stake kyc-verify my-2 w-100">
                      <div className="input-groups select-wrapper ">
                        <Select
                          options={fidexrelationship}
                          value={fidexrelationship.find(
                            (option) => option.value === formValue.relation
                          )}
                          onChange={handlfidexrelationshipChange}
                          placeholder={t("select-relation")}
                          styles={colourStyles}
                          className="reg-select-dropdown "
                        />
                      </div>
                    </div>

                    {ShowOtherRelationInputref.current==true && (
                      <div className="input-groups icons mt-3">
                        <h6 className="input-label">
                          {t("please-specify-your-relationship")}
                        </h6>
                        <div className="input-field ref_input upload_icon">
                          <input
                            type="text"
                            value={otherRelation}
                            minLength={0}
                            maxLength={30}
                            onKeyDown={handleKeyDown}
                            onChange={handleOtherRelationChange}
                            placeholder={t("enter-your-relationship")}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {!relationValidateref.current ? (
                    ""
                  ) : (
                    <p className="errorcss">{relationErrMsgref.current}</p>
                  )}

                  <div className="input-groups icons">
                    <h6 className="input-label">
                      {t("which-fidex-products-you-use")}
                    </h6>
                    <div className="input-checkboxs">
                      <ul>
                        {fidexProductList.map((option, index) => (
                          <li key={index} className="checkbox-container">
                            <input
                              id={`income-${index}`}
                              type="checkbox"
                              className="regular_checkbox"
                              value={option.value}
                              checked={formValue.fidexProducts.includes(
                                option.value
                              )}
                              onChange={handleProductChange}
                            />
                            <div className="custom-check"></div>
                            <label htmlFor={`income-${index}`}>
                              {option.label}
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>
                    {!fidexProductsValidateref.current ? (
                      ""
                    ) : (
                      <p className="errorcss">
                        {fidexProductsErrMsgref.current}
                      </p>
                    )}
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label">
                      {t("expect-annual-volume-in-outflow")}
                    </h6>
                    {/* <div className="input-field ref_input upload_icon kyc_country_dropdown"></div> */}
                    <div className="custom-select-stake kyc-verify my-2 w-100">
                      <div className="input-groups select-wrapper ">
                        <Select
                          options={inflowsFidexdata}
                          value={inflowsFidexdata.find(
                            (option) => option.value === formValue.inflowsFidex
                          )}
                          onChange={handleinflowincomeChange}
                          placeholder={t("select-expected-annual-income")}
                          styles={colourStyles}
                          className="reg-select-dropdown "
                        />
                      </div>
                    </div>
                  </div>

                  {!inflowsFidexValidateref.current ? (
                    ""
                  ) : (
                    <p className="errorcss">{inflowsFidexErrMsgref.current}</p>
                  )}

                  <div className="input-groups icons">
                    <h6 className="input-label">
                      {t("frequency-inflows/incomes-FIDEX")}
                    </h6>
                    {/* <div className="input-field ref_input upload_icon kyc_country_dropdown"></div> */}
                    <div className="custom-select-stake kyc-verify my-2 w-100">
                      <div className="input-groups select-wrapper ">
                        <Select
                          options={Frequency_inflow}
                          value={Frequency_inflow.find(
                            (option) =>
                              option.value === formValue.Frequency_inflowdata
                          )}
                          onChange={handlefrequencyinflowincomeChange}
                          placeholder={t("select-frequency-inflow")}
                          styles={colourStyles}
                          className="reg-select-dropdown "
                        />
                      </div>
                    </div>
                  </div>

                  {!Frequency_inflowdataValidateref.current ? (
                    ""
                  ) : (
                    <p className="errorcss">
                      {Frequency_inflowdataErrMsgref.current}
                    </p>
                  )}

                  <div className="input-groups icons">
                    <h6 className="input-label">
                      {t("expect-annual-volume-outflow")}
                    </h6>
                    {/* <div className="input-field ref_input upload_icon kyc_country_dropdown"></div> */}
                    <div className="custom-select-stake kyc-verify my-2 w-100">
                      <div className="input-groups select-wrapper ">
                        <Select
                          options={outflowsFidexdata}
                          value={outflowsFidexdata.find(
                            (option) => option.value === formValue.outflowsFidex
                          )}
                          onChange={handleoutflowincomeChange}
                          placeholder={t("select-expected-annual-income")}
                          styles={colourStyles}
                          className="reg-select-dropdown "
                        />
                      </div>
                    </div>
                  </div>

                  {!outflowsFidexValidateref.current ? (
                    ""
                  ) : (
                    <p className="errorcss">{outflowsFidexErrMsgref.current}</p>
                  )}

                  <div className="input-groups icons">
                    <h6 className="input-label">
                      {t("common-inflow-transaction")}
                    </h6>
                    <div className="input-checkboxs">
                      <ul>
                        {inflowtransaction.map((option, index) => (
                          <li key={index} className="checkbox-container">
                            <input
                              id={`income-${index}`}
                              type="checkbox"
                              className="regular_checkbox"
                              value={option.value}
                              checked={formValue.inflowTransactionfidex.includes(
                                option.value
                              )}
                              onChange={handleinflowTransactionChange}
                            />
                            <div className="custom-check"></div>
                            <label htmlFor={`income-${index}`}>
                              {option.label}
                            </label>
                          </li>
                        ))}

                        {formValue.inflowTransactionfidex.includes("International Transfers") && (
                          <div className="input-groups icons mt-3">
                            <h6 className="input-label">
                              {t("please-specify-your-inflow-transactions")}
                            </h6>
                            <div className="input-field ref_input upload_icon">
                              <input
                                type="text"
                                onKeyDown={handleKeyDown}
                                value={formValue.specifyInflow}
                                minLength={0}
                                maxLength={50}
                                onChange={handleSpecifyInflowChange}
                                placeholder={t("please-specify")}
                              />
                            </div>
                          </div>
                        )}

                      </ul>
                    </div>

                    {!inflowTransactionfidexValidateref.current ? (
                      ""
                    ) : (
                      <p className="errorcss">
                        {inflowTransactionfidexErrMsgref.current}
                      </p>
                    )}
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label">
                      {t("common-outflow-transaction")}
                    </h6>
                    <div className="input-checkboxs">
                      <ul>
                        {outflowtransaction.map((option, index) => (
                          <li key={index} className="checkbox-container">
                            <input
                              id={`income-${index}`}
                              type="checkbox"
                              className="regular_checkbox"
                              value={option.value}
                              checked={formValue.outflowTransactionfidex.includes(
                                option.value
                              )}
                              onChange={handleoutflowTransactionChange}
                            />
                            <div className="custom-check"></div>
                            <label htmlFor={`income-${index}`}>
                              {option.label}
                            </label>
                          </li>
                        ))}
                        {formValue.outflowTransactionfidex.includes("International Transfers") && (
                          <div className="input-groups icons mt-3">
                            <h6 className="input-label">
                              {t("please-specify-your-outflow-transactions")}
                            </h6>
                            <div className="input-field ref_input upload_icon">
                              <input
                                type="text"
                                value={formValue.specifyOutflow}
                                minLength={0}
                                onKeyDown={handleKeyDown}
                                maxLength={50}
                                onChange={handleSpecifyOutflowChange}
                                placeholder={t("please-specify")}
                              />
                            </div>
                          </div>
                        )}

                      </ul>

                      {!outflowTransactionfidexValidateref.current ? (
                        ""
                      ) : (
                        <p className="errorcss">
                          {outflowTransactionfidexErrMsgref.current}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label">
                      {t("which-country-your-bank-account")}
                    </h6>
                    {/* <div className="input-field ref_input upload_icon kyc_country_dropdown"></div> */}
                    <div className="custom-select-stake kyc-verify my-2 w-100">
                      <div className="input-groups select-wrapper ">
                        <Select
                          options={options}
                          value={options.find(
                            (option) => option.value === formValue.countryofbank
                          )}
                          onChange={handleCountryChange}
                          placeholder={t("select-country")}
                          styles={colourStyles}
                          className="reg-select-dropdown "
                        />
                      </div>
                    </div>
                  </div>

                  {!countryValidateref.current ? (
                    ""
                  ) : (
                    <p className="errorcss">{countryErrMsgref.current}</p>
                  )}

              {   
                buttonLoader ==false ?
                  <div className="Submit my-4" onClick={submit}>
                    <Link>
                      <button>{t("next")}</button>
                    </Link>
                  </div>
                  :
                  <div className="Submit my-4" >
                  <Link>
                    <button>{t("loading")}...</button>
                  </Link>
                </div> 
              }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
