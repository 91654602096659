import React, { useEffect } from "react";
import Header from "./Header";
import { stakeOpt } from "../utils/mockData2";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Side_bar from "./Side_bar";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import Moment from "moment";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const Dashboard = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [total, settotal] = useState(0);
  const [balanceDetails, setbalanceDetails] = useState([]);
  const [AvailablePrice, setAvailablePrice] = useState(0);
  const [profileData, setprofileData] = useState("");
  const [siteLoader, setSiteLoader] = useState(false);
  const [balanceDatas, setbalanceDatas] = useState([]);
  const [totalINRPrice, setToatalINRPrice] = useState(0);
  const [inorderPrice, setinorderPrice] = useState(0);
  const recordPerPage = 5;
  const [perpage, setperpage] = useState(5);
  const [search, setsearch, searchref] = useState("");

  const navigate = useNavigate();
  const { t } = useTranslation();

  const depositNav = () => {
    navigate("/deposit");
  };
  const [mode, setmode] = useState("");

  useEffect(() => {
    const currentMode = localStorage.getItem("Mode");
    setmode(currentMode ? currentMode : "light");
    gettotalbalnce();
  }, [0]);



  const [usdtBalace ,setusdtBalance]= useState(0)
  const [eurBalance ,setEurBalance]= useState(0)
  const [currency ,setcurrency]= useState("EUR")

  const colourStyles = (isDarkTheme) => ({
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused
          ? isDarkTheme ? "#555" : "#33c2b9" // Darker background for dark theme
          : null, // Transparent when not focused
        color: isFocused ? "#fff" : isDarkTheme ? "#fff" : "#000", // White text for dark theme, black for light
        cursor: isDisabled ? "not-allowed" : "pointer",
        border: "none", // Remove border for all states
      };
    },
    control: (styles) => ({
      ...styles,
      border: "none", // Remove border from the control element
      boxShadow: "none", // Remove box shadow
      backgroundColor: isDarkTheme ? "#000" : "#f7f7f7", // Black for dark theme, light gray for light theme
      color: isDarkTheme ? "#fff" : "#000", // White text for dark theme, black for light
      "&:hover": {
        border: "none", // Remove border on hover
      },
    }),
  });
  
  
  
  const choose = (data) => {
    setcurrency(data.value);
    gettotalbalnce();
  }
  const currencyOption = [
    { value: "USD", label: t("usd") },
    { value: "EUR", label: t("eur") },
  ];

  const gettotalbalnce = async ()=>{
    try{

      var data = {
        apiUrl: apiService.getCurrencyConvertion
      };
  
      // setbalanceLoader(true);
      var resp = await getMethod(data);
      // setbalanceLoader(false);

      setusdtBalance(resp.totalUsdValue);
      setEurBalance(resp.totalEurValue);
    }catch(err){
    }
  }
  

  const withdrawNav = () => {
    navigate("/Withdraw");
  };

  const convertNav = () => {
    navigate("/swap");
  };

  useEffect(() => {
    getPortfolio();
    getUserbalance(currentPage);
    getUserTotalbalance(currentPage);
  }, [0]);

  const handlePageChange = (event, value) => {

    setCurrentPage(value);
    var current_page = +value * 5;
    var prev_page = +current_page - 5;
    var resp_balance = [];
    for (var i = prev_page; i < current_page; i++) {
      if (balanceDatas[i] !== undefined) {
        resp_balance.push(balanceDatas[i]);
      }
    }
    setbalanceDetails(resp_balance);
  };

  const getUserTotalbalance = async (pages) => {
    var obj = {
      perpage: perpage,
      page: pages,
      search: searchref.current,
    };
    var data = {
      apiUrl: apiService.getUserTotalbalance,
      payload: obj,
    };
    setSiteLoader(true);
    var resp = await postMethod(data);
    setSiteLoader(false);

    if (resp.status == true) {
      var balanceData = resp.balance;
      setToatalINRPrice(balanceData.total_balance);
      setAvailablePrice(balanceData.available_balance);
      setinorderPrice(balanceData.inorder_balance);
    }
  };

  const getUserbalance = async (pages) => {
    // setSiteLoader(false);
    var obj = {
      perpage: perpage,
      page: pages,
      search: searchref.current,
    };

    var data = {
      apiUrl: apiService.getUserBalance,
      payload: obj,
    };
    setSiteLoader(true);

    var resp = await postMethod(data);
    setSiteLoader(false);

    if (resp.status == true) {
      // setSiteLoader(false);
      console.log(resp.Message, "=-=-=-resp.Message=-=-=-");
      var balanceData = resp.Message;
      setbalanceDatas(balanceData);

      var current_page = +resp.current * 5;
      var prev_page = +current_page - 5;
      var resp_balance = [];
      for (var i = prev_page; i < current_page; i++) {
        if (balanceData[i] !== undefined) {
          resp_balance.push(balanceData[i]);
        }
      }
      // resp_balance = resp_balance.filter(Boolean);
      setbalanceDetails(resp_balance);
      var totalnumber = resp.total;
      settotal(resp.total);
      console.log(resp.total, "resp.totalresp.total");
      var balanceData = resp.balance;
    } else {
    }
  };

  const getPortfolio = async () => {
    var data = {
      apiUrl: apiService.portfolioBalance,
    };
    setSiteLoader(true);

    var resp = await getMethod(data);
    setSiteLoader(false);

    if (resp.status == true) {
      var balanceData = resp.balance;
      setToatalINRPrice(balanceData.total_balance);
      setAvailablePrice(balanceData.available_balance);
      setinorderPrice(balanceData.inorder_balance);
    } else {
    }
  };

  return (
    <>
      <section>
        <Header />
      </section>

      <main className="dashboard_main assets_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 pad-lft">
              <Side_bar />
            </div>

            {siteLoader == true ? (
              <div className="loadercss">
                <Bars
                  height="80"
                  width="80"
                  color="#33c2b9"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : (
              <div className="col-lg-10 pad-lft">
                <section className="asset_section">
                  <div className="row">
                    <div className="asset_title">{t("assets")}</div>
                    <div className="col-lg-12">
                      {/* Estimated Value */}
                      <div className="esti-container">

                        <span className="esti-usdt asset-usdt-scroll">{t("esti-value")}</span>
                        {currency =="USD" ?
                          <div className="d-flex currecydash">
                          <p className="total_balance_amt">
                          {usdtBalace == "" ||
                          usdtBalace == null ||
                          usdtBalace == undefined
                            ? 0.0
                            : usdtBalace === "0"
                            ? "0.00"
                            : usdtBalace}{" "}
                          {/* <span className="usd_text">{t("usd")}</span> */}
                        </p>
                        <Select
                               options={currencyOption}
                               styles={colourStyles}
                               onChange={choose}
                               placeholder={currencyOption[0].label}
                               className="reg-select-dropdown currecydash2"
                               value={currencyOption.find(
                                (option) =>
                                  option.value === currency
                              )}
                             />
                        </div>
                          :
                          <div className="d-flex currecydash">
                          <p className="total_balance_amt">
                            {eurBalance == "" ||
                            eurBalance == null ||
                            eurBalance == undefined
                              ? 0.0
                              : eurBalance === "0"
                              ? "0.00"
                              : eurBalance}{" "}
                            {/* <span className="usd_text">{t("eur")}</span> */}
                           
                          </p>
                           <Select
                           options={currencyOption}
                           styles={colourStyles}
                           onChange={choose}
                           placeholder={currencyOption[0].label}
                           className="reg-select-dropdown currecydash2"
                           value={currencyOption.find(
                            (option) =>
                              option.value === currency
                          )}
                         />
                         </div>
                          }
                        {/* <span className="esti-usdt asset-usdt-scroll"> */}
                          {/* {AvailablePrice == "" ||
                          AvailablePrice == null ||
                          AvailablePrice == undefined
                            ? 0.0
                            : AvailablePrice.toFixed(4)}{" "}
                          <span className="esti-span">USDT</span>{" "} */}
                        {/* </span> */}
                        {/* <span className="esti-num">~ $0.00</span> */}
                      </div>
                      {/* buttons */}
                      <div className="asset-btn-wrapper">
                        <button className="asset-btn" onClick={depositNav}>
                          {t("deposit")}
                        </button>
                        {mode == "pro" ? (
                          <button className="asset-btn" onClick={withdrawNav}>
                            {t("withdraw")}
                          </button>
                        ) : (
                          ""
                        )}
                        <button className="asset-btn" onClick={convertNav}>
                          {t("convert")}
                        </button>
                      </div>
                      {/* Assets list */}
                      <h5 className="identify-title">{t("assets-list")}</h5>
                      <div
                        class="nav nav-tabs border--b_line"
                        id="nav-tab"
                        role="tablist"
                      >
                        <button
                          class="nav-link active"
                          id="nav-home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-home"
                          type="button"
                          role="tab"
                          aria-controls="nav-home"
                          aria-selected="true"
                        >
                          {t("crypto")}
                        </button>
                        {/* <button
                        class="nav-link "
                        id="nav-profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-profile"
                        type="button"
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                      >
                        {t("fiat")}
                      </button> */}
                      </div>

                      <div class="tab-content" id="nav-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="nav-home"
                          role="tabpanel"
                          aria-labelledby="nav-home-tab"
                          tabindex="0"
                        >
                          <div className="table-responsive table-cont dash_table_content">
                            <table className="table ">
                              <thead>
                                <tr className="stake-head font-satoshi">
                                  <th>{t("assets")}</th>
                                  <th className="table_center_text opt-nowrap txt-center pad-left-23">
                                    {t("on-orders")}
                                  </th>
                                  <th className="table_center_text opt-nowrap txt-center pad-left-23">
                                    {t("avail-balance")}
                                  </th>
                                  <th className="table_action">
                                    {t("total-balance")}
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {balanceDetails && balanceDetails.length > 0 ? (
                                  balanceDetails.map((item, i) => {
                                    return (
                                      <tr key={i}>
                                        <td className="table-flex">
                                          <img
                                            src={item?.currencyImage}
                                            alt=""
                                          />
                                          <div className="table-opt-name">
                                            <h4 className="opt-name font-satoshi font_14">
                                              {item?.currencysymbol}
                                            </h4>
                                            <h3 className="opt-sub font-satoshi font_14">
                                              {item?.currencyName}
                                            </h3>
                                          </div>
                                        </td>
                                        <td className="opt-term font-satoshi font_14 table_center_text pad-left-23">
                                          {parseFloat(item?.holdAmount).toFixed(
                                            4
                                          )}{" "}
                                          {item?.currencysymbol}
                                        </td>
                                        <td className="opt-term font-satoshi font_14 table_center_text pad-left-23">
                                          {parseFloat(
                                            item?.currencyBalance
                                          ).toFixed(4)}{" "}
                                          {item?.currencysymbol}
                                        </td>
                                        <td className="opt-term font-satoshi font_14 text-end pad-left-23">
                                          {parseFloat(
                                            item?.currencyBalance +
                                              parseFloat(item?.holdAmount)
                                          ).toFixed(4)}{" "}
                                          {item?.currencysymbol}{" "}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td
                                      colSpan={5}
                                      className="text-center py-5"
                                    >
                                      <div className="empty_data">
                                        <div className="empty_data_img">
                                          <img
                                            src={require("../assets/No-data.png")}
                                            width="100px"
                                          />
                                        </div>
                                        <div className="no_records_text">
                                          {t("no-records-found")}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                            {balanceDetails && balanceDetails.length > 0 ? (
                              <div className="pagination">
                                <Stack spacing={2}>
                                  <Pagination
                                    count={Math.ceil(total / recordPerPage)}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    size="small"
                                    renderItem={(item) => (
                                      <PaginationItem
                                        slots={{
                                          previous: ArrowBackIcon,
                                          next: ArrowForwardIcon,
                                        }}
                                        {...item}
                                        sx={{
                                          "&.Mui-selected": {
                                            backgroundColor: "#33c2b9",
                                            color: "#fff",
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                </Stack>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="nav-profile"
                          role="tabpanel"
                          aria-labelledby="nav-profile-tab"
                          tabindex="0"
                        ></div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default Dashboard;
