import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Side_bar from "./Side_bar";
import Day from "../assets/night.png";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Settings = () => {
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      const isDark = savedTheme === "dark";
      setIsDarkTheme(isDark);
      document.body.classList.toggle("dark", isDark);
    }
  }, []);

  const toggleTheme = () => {
    const newTheme = isDarkTheme ? "light" : "dark";
    localStorage.setItem("theme", newTheme);
    setIsDarkTheme(!isDarkTheme);

    if (newTheme === "dark") {
      document.body.classList.add("dark");
      toast.success(t("dark-theme-activated"));
      // document.body.classList.remove("light");
    } else {
      // document.body.classList.add("light");
      document.body.classList.remove("dark");
      toast.success(t("light-theme-activated"));
    }

    // var pathsplit = window.location.pathname.split("/");

    // if (pathsplit.length > 0) {
    //   if (pathsplit[1] === "trade") {
    //     socket.emit("loadchart", newTheme);
    //   }
    // }
  };

  return (
    <>
      <section>
        <Header />
      </section>

      <main className="dashboard_main settings_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 pad-lft">
              <Side_bar />
            </div>

            <div className="col-lg-10">
              <section className="asset_section settings_main">
                <div className="row">
                  <div className="settings">
                    <div className="settings_head">
                      <span className="reward-title">{t("settings")}</span>
                    </div>
                    {/* <div className="setting-lang ">
                      <div>
                        <h5 className="set-title">Language</h5>
                        <h6 className="set-content width-50">
                          Your language selection applies to FIDEX emails,
                          in-app notifications and all devices you're logged in
                          to.
                        </h6>
                      </div>
                      <div className="set-english">
                        <span className="set-content">English</span>
                        <span className="set-icon">
                          <i class="fa-solid fa-caret-down"></i>
                        </span>
                      </div>
                    </div> */}
                    {/* <div className="setting-lang">
                      <div>
                        <h5 className="set-title">Currency</h5>
                      </div>
                      <div>
                        <span className="set-content">USD</span>
                        <span className="set-icon">
                          <i class="fa-solid fa-caret-down"></i>
                        </span>
                      </div>
                    </div> */}
                    <div className="setting-lang ">
                      <div>
                        <h5 className="set-title">{t("theme")}</h5>
                      </div>

                      <div>
                        <input
                          type="checkbox"
                          id="toggle-theme"
                          className="toggle-check"
                          checked={isDarkTheme}
                          onChange={toggleTheme}
                        />
                        <label
                          htmlFor="toggle-theme"
                          className="toggle-notification"
                        ></label>
                      </div>
                    </div>
                    <div className="setting-lang">
                      <div>
                        <h5 className="set-title">{t("notification")}</h5>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          name=""
                          id="toggle-notify"
                          className="toggle-check"
                        />
                        <label
                          htmlFor="toggle-notify"
                          className="toggle-notification"
                        ></label>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Settings;
