import React, { useEffect } from "react";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function Verification() {
  const [OTP, setOTP] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [counter, setCounter] = useState(60);
  const [isResendVisible, setIsResendVisible] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [toastId, setToastId] = useState(null);

  const showSuccessToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.success(message);
    setToastId(newToastId);
  };

  const showErrorToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.error(message);
    setToastId(newToastId);
  };

    const handleblur =()=>{
     if(OTP==''){
      setvalidationErr(true);
    } else if (OTP.length === 4) {
      setvalidationErr(false);
    }
  };
  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsResendVisible(true);
    }
  }, [counter]);
  const [validationErr, setvalidationErr] = useState();
  const submit = async () => {
    try {
      if (OTP !== "") {
        var obj = {
          emailOtp: OTP,
          email: localStorage.getItem("useremail"),
        };

        var data = {
          apiUrl: apiService.emailotpverify,
          payload: obj,
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setbuttonLoader(false);
        if (resp.status == true) {
          showSuccessToast(resp.Message);
          navigate("/login");
        } else {
          showErrorToast(resp.Message);
        }
      } else {
        // showErrorToast(t("enter-otp"));
        setvalidationErr(true);
      }
    } catch (error) {
      setbuttonLoader(false);
    }
  };

  const handleResend = async () => {
    setCounter(60);
    setIsResendVisible(false);
    // Add logic to resend the OTP
    try {
      var obj = {
        email: localStorage.getItem("useremail"),
      };

      var data = {
        apiUrl: apiService.resendCode,
        payload: obj,
      };
      setbuttonLoader(true);

      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp.status) {
        showSuccessToast(resp.Message);
      } else {
        showErrorToast(resp.Message);
      }
    } catch (error) {
      setbuttonLoader(false);
    }
  };

  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>{" "}
      <div>
        <div className="Verification enable2fa-pt">
          <div className="container">
            <div>
              <Link to="/register">
                <h6>
                  {" "}
                  <i class="fa-solid fa-arrow-left-long mr-3"></i> {t("signup")}
                </h6>
              </Link>
              <div className="row justify-content-center cards">
                <div className="col-lg-4">
                  <span class="heading">{t("verification")}</span>

                  <div className="notify"> {t("tfa-notify-content")} </div>

                  <div className="input-groups icons">
                    <h6 className="input-label">{t("email-verify-code")}</h6>
                    <input
                      type="text"
                      id="numberInput"
                      min="0"
                      name="OTP"
                      maxLength={4}
                      value={OTP}
                      onBlur={handleblur}
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allows only numbers
                      }}
                      onChange={(e) => setOTP(e.target.value)}
                      className="input-field"
                      placeholder={t("enter-code")}
                    />
                  </div>

                  {validationErr == true ? (
                    <p className="errorcss">{t("enter-otp")}</p>
                  ) : (
                    ""
                  )}
                  {/* <div className="input-groups icons mt-4">
                    <h6 className="input-label">{t("phone-no-verify-code")}</h6>
                    <input
                      className="input-field"
                      placeholder={t("enter-code")}
                    />
                  </div> */}

                  <div className="Submit my-4" onClick={submit}>
                    {buttonLoader == false ? (
                      <button>{t("submit")}</button>
                    ) : (
                      <button>{t("loading")} ...</button>
                    )}
                  </div>

                  <div className="foot">
                    <p>
                      {t("didnt-receive-code")}{" "}
                      <a>
                        {isResendVisible ? (
                          <span
                            onClick={handleResend}
                            className="cursor-pointer"
                          >
                            <a> {t("resend")}</a>
                          </span>
                        ) : (
                          <span className="textgreen">{counter}s</span>
                        )}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
