import React from "react";
import Header from "./Header";
import { useTranslation } from "react-i18next";

const Changepassword = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>{" "}
      <div>
        <div className="Verification enable2fa-pt">
          <div className="container">
            <div>
              <h6>
                {" "}
                <i className="fa-solid fa-arrow-left-long mr-3"></i>{" "}
                {t("security")}
              </h6>
              <div className="row justify-content-center cards">
                <div className="col-lg-4">
                  <span className="heading">{t("change-login-password")}</span>
                  <div className="input-groups icons">
                    <h6 className="input-label">{t("old-password")}</h6>
                    <input
                      className="input-field"
                      placeholder={t("enter-old-password")}
                    />
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label">{t("new-password")}</h6>
                    <input
                      className="input-field"
                      placeholder={t("enter-new-password")}
                    />
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label">{t("confirm-password")}</h6>
                    <input
                      className="input-field"
                      placeholder={t("re-enter-your-new-password")}
                    />
                  </div>

                  <div className="Submit my-4">
                    <button>{t("confirm")}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Changepassword;
