import React, { useEffect, useMemo } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import { postMethod, getMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";
import Select from "react-select";
import countryList from "react-select-country-list";
import { env } from "../core/service/envconfig";
import { useTranslation } from "react-i18next";

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#33c2b9" : null,
      color: isFocused ? "#fff" : "#000",
      cursor: isDisabled ? "not-allowed" : "pointer",
    };
  },
};

export default function FiatDeposit() {
  const [OTP, setOTP] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [counter, setCounter] = useState(60);
  const [isResendVisible, setIsResendVisible] = useState(false);
  const [value, setValue] = useState("");
  const [selectCheck, setSelectCheck] = useState(false);
  const [selectCompCheck, setSelectCompCheck] = useState(false);
  const [selectProof, setSelectProof] = useState(false);
  const [isShowStatement, setIsShowStament] = useState(false);
  const [proofchoosingName, setproofchoosingName] = useState();
  const options = useMemo(() => countryList().getData(), []);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const changeHandler = (value) => {
    setValue(value);
  };

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsResendVisible(true);
    }
  }, [counter]);

  // checkbox button functions
  const handleCheck = () => {
    setSelectCheck((prevCheck) => !prevCheck);
  };
  const handleCheckComp = () => {
    setSelectCompCheck((prevCheck) => !prevCheck);
  };
  const handleCheckProof = () => {
    setSelectProof((prevCheck) => !prevCheck);
  };
  const handleShowStatement = (value) => {
    console.log(value, "value");
    setIsShowStament(!isShowStatement);
    setproofchoosingName(value);
    setSelectProof(false);
  };

  // AUTHOR OF JOTHI


  const getTodayDate = () => {
    return new Date().toISOString().split('T')[0];
  };

  const registrationOptions = [
    { value: "Corporation", label: t("corporation") },
    {
      value: "ChildLimited Liability Corporation (LLC)",
      label: t("childlimited-liability-corporation"),
    },
    { value: "Non-Profit", label: t("non-profit") },
    { value: "Partnership", label: t("partnership") },
    { value: "Sole Proprietorship", label: t("sole-proprietorship") },
    { value: "Trust", label: t("trust") },
    {
      value: "Unincorporated Association",
      label: t("unincorporated-association"),
    },
  ];

  const documentOptions = [
    { value: "Utility Bill", label: t("utility-bill") },
    { value: "Bank Statement ", label: t("bank-statement") },
    { value: "Credit Card Statement", label: t("credit-card-statement") },
    {
      value: "Proof of Residency Certificate",
      label: t("proof-of-residency-certificate"),
    },
  ];

  const addressProofOptions = [
    { value: "Bank Statement", label: t("bank-statement") },
    { value: "Credit Card Statement", label: t("credit-card-statement") },
    { value: "Utility Bill", label: t("utility-bill") },
    { value: "Salary Slip", label: t("salary-slip") },
    { value: "Insurance Statement", label: t("insurance-statement") },
    { value: "Tax Document", label: t("tax-document") },
    { value: "Residence Certificate", label: t("residence-certificate") },
  ];

  useEffect(() => {
    getStep();
  }, [0]);

  const initialFormValue = {
    registrationtype: "",
    registrationnumber: "",
    Dateofformation: "",
    Country: "",
  };

  const [formValue, setFormValue,formValueref] = useState(initialFormValue);
  const [validcorporateFormationproof, setvalidcorporateFormationproof] =
    useState(0);
  const [corporateFormationproofLoad, setcorporateFormationproofLoad] =
    useState(false);
  const [
    corporateFormationproof,
    setcorporateFormationproof,
    corporateFormationproofref,
  ] = useState("");
  const [
    corporateFormationproofname,
    setcorporateFormationproofname,
    corporateFormationproofnameref,
  ] = useState("");
  const [validcompanyproof, setvalidcompanyproof] = useState(0);
  const [companyproofLoad, setcompanyproofLoad] = useState(false);
  const [companyproof, setcompanyproof, companyproofref] = useState("");
  const [companyproofname, setcompanyproofname, companyproofnameref] =
    useState("");
  const [validaddressProof, setvalidaddressProof] = useState(0);
  const [addressProofup, setaddressProof, addressProofref] = useState("");
  const [addressproofname, setaddressproofname, addressproofnameref] =
    useState("");
  const [addressProofLoad, setaddressProofLoad] = useState(false);
  const { registrationtype, registrationnumber, Dateofformation, Country } =
    formValue;

  const [
    registrationtypevallidate,
    setregistrationtypevallidate,
    registrationtypevallidateref,
  ] = useState("");
  const [
    registrationtypeErrMsg,
    setregistrationtypeErrMsg,
    registrationtypeErrMsgref,
  ] = useState("");
  const [regnumErrMsg, setregnumErrMsg, regnumErrMsgref] = useState("");
  const [regnumvallidate, setregnumvallidate, regnumvallidateref] =
    useState("");
  const [dofvallidate, setdofvallidate, dofvallidateref] = useState("");
  const [dofErrMsg, setdofErrMsg, dofErrMsgref] = useState("");
  const [countryErrMsg, setcountryErrMsg, countryErrMsgref] = useState("");
  const [countryvallidate, setcountryvallidate, countryvallidateref] =
    useState("");
  const [companyproofErrMsg, setcompanyproofErrMsg, companyproofErrMsgref] =
    useState("");
  const [
    companyproofvallidate,
    setcompanyproofvallidate,
    companyproofvallidateref,
  ] = useState("");
  const [addressProofErrMsg, setaddressProofErrMsg, addressProofErrMsgref] =
    useState("");
  const [
    addressProofvallidate,
    setaddressProofvallidate,
    addressProofvallidateref,
  ] = useState("");
  const [corporateFormErrMsg, setcorporateFormErrMsg, corporateFormErrMsgref] =
    useState("");
  const [
    corporateFormvallidate,
    setcorporateFormvallidate,
    corporateFormvallidateref,
  ] = useState("");

  const handleCountryChange = (selectedCountry) => {
    const updatedFormValue = {
      ...formValue,
      Country: selectedCountry.value,
    };
    setFormValue(updatedFormValue);
    validation(updatedFormValue)
  };

  const handletypeChange = (selectOption) => {
    const updatedFormValue = {
      ...formValue,
      registrationtype: selectOption.value,
    };
    setFormValue(updatedFormValue);
    validation(updatedFormValue)
  };
  const handleChange = async (e) => {
    const { name, value } = e.target;
    let sanitizedValue = value.replace(/\s/g, "");
    let formData = { ...formValue, ...{ [name]: sanitizedValue } };
    setFormValue(formData);
    validation(formValueref.current); 
    };
    

  const imageUpload = (type, val) => {
    try {
      const fileExtension = val.name.split(".").at(-1);
      const fileSize = val.size;
      const fileName = val.name;
      if (
        fileExtension != "png" &&
        fileExtension != "jpg" &&
        fileExtension != "jpeg"
      ) {
        toast.error(t("file-does-not-support"));
      } else if (fileSize > 10000000) {
        toast.error(t("please-upload-file-smaller-than-1mb"));
      } else {
        type == "corporateFormation"
          ? setcorporateFormationproofLoad(true)
          : type == "companyShareRegistry"
          ? setcompanyproofLoad(true)
          : setaddressProofLoad(true);
        const data = new FormData();
        data.append("file", val);
        data.append("upload_preset", env.upload_preset);
        data.append("cloud_name", env.cloud_name);
        fetch(
          "https://api.cloudinary.com/v1_1/" + env.cloud_name + "/auto/upload",
          { method: "post", body: data }
        )
          .then((resp) => resp.json())
          .then((data) => {
            if (type == "corporateFormation") {
              setcorporateFormationproofLoad(false);
              setvalidcorporateFormationproof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setvalidcorporateFormationproof(1);
              }
              setcorporateFormationproof(data.secure_url);
              setcorporateFormationproofname(val.name);
              validation(formValue);
            }
            if (type == "companyShareRegistry") {
              setcompanyproofLoad(false);
              setvalidcompanyproof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setvalidcompanyproof(1);
              }
              setcompanyproofname(val.name);
              setcompanyproof(data.secure_url);
              validation(formValue);
            }
            if (type == "addressProof") {
              setaddressProofLoad(false);
              setvalidaddressProof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setvalidaddressProof(1);
              }
              setaddressproofname(val.name);
              setaddressProof(data.secure_url);
              validation(formValue);
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error(t("please-try-again-later"));
          });
      }
    } catch (error) {
      toast.error(t("please-try-again-later"));
    }
  };

  const handleAddressProofChange = (selectedOption) => {
    const updatedFormValue = {
      ...formValue,
      addressProof: selectedOption.value,
    };
    setFormValue(updatedFormValue);
    setproofchoosingName(selectedOption.value);
    setaddressproofname("")
    setaddressProof("")
    setvalidaddressProof(0)
    validation(formValue);
  };

  // const validation = (formValue) => {
  //   try {
  //   const alphanumericRegex = /^[a-zA-Z0-9]+$/;
  //   const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    
  //   // Check registration type
  //   if (!formValue.registrationtype || formValue.registrationtype === "" || formValue.registrationtype == null || formValue.registrationtype == undefined ) {
  //   setregistrationtypeErrMsg(t("register-type-required"));
  //   setregistrationtypevallidate(true);
  //   } else 
  //   if (!formValue.registrationnumber || formValue.registrationnumber === "" || formValue.registrationnumber == null || formValue.registrationnumber == undefined) {
  //   setregnumErrMsg(t("register-number-required"));
  //   setregnumvallidate(true);
  //   setregistrationtypevallidate(false);
  //   } else if (!alphanumericRegex.test(formValue.registrationnumber) || formValue.registrationnumber.length < 5 || formValue.registrationnumber.length > 30) {
  //   setregnumErrMsg(t("register-number-invalid"));
  //   setregnumvallidate(true);
  //   setregistrationtypevallidate(false);
  //   } else
  //   if (!formValue.Dateofformation || formValue.Dateofformation === "" || formValue.Dateofformation == null || formValue.Dateofformation == undefined) {
  //   setdofErrMsg(t("date-required-field"));
  //   setregnumvallidate(false);
  //   setdofvallidate(true);
  //   }  else  if (!formValue.Country || formValue.Country === "" || formValue.Country == null || formValue.Country == undefined) {
  //   setcountryErrMsg(t("country-required-field"));
  //   setcountryvallidate(true);
  //   setdofvallidate(false);
  //   setdofErrMsg("");
  //   }else if (!corporateFormationproofref.current || corporateFormationproofref.current === "" || corporateFormationproofref.current == null || corporateFormationproofref.current == undefined) {
  //   setcorporateFormErrMsg(t("corporation-proof-required"));
  //   setcorporateFormvallidate(true);
  //   } else  if (!companyproofref.current || companyproofref.current === "" || companyproofref.current == null || companyproofref.current == undefined) {
  //   setcompanyproofErrMsg(t("company-proof-required"));
  //   setcompanyproofvallidate(true);
  //   setcountryvallidate(false);
  //   setcorporateFormvallidate(false);
  //   } else  if (!addressProofref.current || addressProofref.current === "" || addressProofref.current == null || addressProofref.current == undefined) {
  //   setaddressProofErrMsg(t("address-proof-required"));
  //   setaddressProofvallidate(true);
  //   setcompanyproofvallidate(false);
  //   }  else {
  //   setcorporateFormvallidate(false);
  //   setregistrationtypevallidate(false);
  //   setregnumvallidate(false);
  //   setdofvallidate(false);
  //   setcountryvallidate(false);
  //   setcompanyproofvallidate(false);
  //   setaddressProofvallidate(false);
  //   setcorporateFormvallidate(false); 
  //   }
  //   } catch (err) {
  //   console.error(err);
  //   }
  //   };
    

  const validation = (formValue) => {
    try {
      // Define the regex patterns
      const alphanumericRegex = /^[a-zA-Z0-9]+$/;
      const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  
      // Initialize a flag to check if there are any errors
      let hasError = false;
  
      // Reset all error messages and validation states
      setregistrationtypeErrMsg("");
      setregistrationtypevallidate(false);
      setregnumErrMsg("");
      setregnumvallidate(false);
      setdofErrMsg("");
      setdofvallidate(false);
      setcountryErrMsg("");
      setcountryvallidate(false);
      setcorporateFormErrMsg("");
      setcorporateFormvallidate(false);
      setcompanyproofErrMsg("");
      setcompanyproofvallidate(false);
      setaddressProofErrMsg("");
      setaddressProofvallidate(false);
  
      // Registration Type Validation
      if (
        !formValue.registrationtype ||
        formValue.registrationtype === "" ||
        formValue.registrationtype == null
      ) {
        setregistrationtypeErrMsg(t("register-type-required"));
        setregistrationtypevallidate(true);
        hasError = true;
      }
  
      // Registration Number Validation
      if (!hasError) {
        if (
          !formValue.registrationnumber ||
          formValue.registrationnumber === "" ||
          formValue.registrationnumber == null
        ) {
          setregnumErrMsg(t("register-number-required"));
          setregnumvallidate(true);
          setregistrationtypevallidate(false);
          hasError = true;
        } else if (
          !alphanumericRegex.test(formValue.registrationnumber) ||
          formValue.registrationnumber.length < 5 ||
          formValue.registrationnumber.length > 30
        ) {
          setregnumErrMsg(t("register-number-invalid"));
          setregnumvallidate(true);
          setregistrationtypevallidate(false);
          hasError = true;
        }
      }
  
      // Date of Formation Validation
      if (!hasError) {
        if (
          !formValue.Dateofformation ||
          formValue.Dateofformation === "" ||
          formValue.Dateofformation == null
        ) {
          setdofErrMsg(t("date-required-field"));
          setregnumvallidate(false);
          setdofvallidate(true);
          hasError = true;
        }
      }
  
      // Country Validation
      if (!hasError) {
        if (!formValue.Country || formValue.Country === "" || formValue.Country == null) {
          setcountryErrMsg(t("country-required-field"));
          setcountryvallidate(true);
          setdofvallidate(false);
          setdofErrMsg("");
          hasError = true;
        }
      }
  
      // Corporate Formation Proof Validation
      if (!hasError) {
        if (
          !corporateFormationproofref.current ||
          corporateFormationproofref.current === "" ||
          corporateFormationproofref.current == null
        ) {
          setcorporateFormErrMsg(t("corporation-proof-required"));
          setcorporateFormvallidate(true);
          hasError = true;
        }
      }
  
      // Company Proof Validation
      if (!hasError) {
        if (
          !companyproofref.current ||
          companyproofref.current === "" ||
          companyproofref.current == null
        ) {
          setcompanyproofErrMsg(t("company-proof-required"));
          setcompanyproofvallidate(true);
          setcountryvallidate(false);
          setcorporateFormvallidate(false);
          hasError = true;
        }
      }
  
      // Address Proof Validation
      if (!hasError) {
        if (
          !addressProofref.current ||
          addressProofref.current === "" ||
          addressProofref.current == null
        ) {
          setaddressProofErrMsg(t("address-proof-required"));
          setaddressProofvallidate(true);
          setcompanyproofvallidate(false);
          hasError = true;
        }
      }
  
      // If no errors, reset all validation states
      if (!hasError) {
        console.log("Validation passed");
        setregistrationtypevallidate(false);
        setregnumvallidate(false);
        setdofvallidate(false);
        setcountryvallidate(false);
        setcorporateFormvallidate(false);
        setcompanyproofvallidate(false);
        setaddressProofvallidate(false);
      }
    } catch (err) {
      console.error("Validation error", err);
    }
  };

  
    const submit = async () => {
      try{
        validation();
        // Check if any validation errors exist
        if (
        registrationtypevallidateref.current == true ||
        regnumvallidateref.current == true ||
        dofvallidateref.current == true ||
        countryvallidateref.current == true ||
        companyproofvallidateref.current == true ||
        addressProofvallidateref.current == true ||
        corporateFormvallidateref.current ==true ||
        companyproofref.current ==""||
        addressProofref.current =="" ||
        formValue.registrationtype=="" ||
        formValue.registrationnumber=="" ||
        formValue.Dateofformation=="" ||
        formValue.Country==""  ||
        proofchoosingName =="" ||
        proofchoosingName ==null
        ) {
          validation(formValue);
        }else{
        formValue["verifiedSteps"] = "Step-2";
        formValue["corporateFormationproof"] = corporateFormationproofref.current;
        formValue["companyproof"] = companyproofref.current;
        formValue["addressProof"] = addressProofref.current;
        formValue["addressProofName"] = proofchoosingName;
        
        setbuttonLoader(true);
        const resp = await postMethod({
        apiUrl: apiService.kybSecond,
        payload: formValue,
        });
        setbuttonLoader(false);
        
        if (resp.status === true) {
        toast.success(resp.Message);
        navigate("/KybInformation3");
        } else {
        toast.error(resp.Message);
        }
        };
      }catch(error){
            console.log(error,"--=-=-=-=error")
      }
   
  }
    


  const getStep = async () => {
    try {
      var data = {
        apiUrl: apiService.get_kyb_details,
      };
      var resp = await getMethod(data);
      if (resp.data !== null) {
        var kybData = resp.data;
        formValue.registrationtype = kybData.registrationtype;
        formValue.registrationnumber = kybData.registrationnumber;
        formValue.Dateofformation = kybData.Dateofformation;
        formValue.Country = kybData.Country;
        formValue.verifiedSteps = kybData.verifiedSteps;
        formValue.corporateFormationproof = kybData.corporateFormationproof;
        if (kybData.companyproof) {
          setcompanyproof(kybData.companyproof);
          setvalidcompanyproof(2);
        }
        if (kybData.addressProof) {
          setcorporateFormationproof(kybData.corporateFormationproof);
          setvalidcorporateFormationproof(2);
        }
        if (kybData.addressProof) {
          setaddressProof(kybData.addressProof);
          setvalidaddressProof(2);
          setSelectProof(false);
          setIsShowStament(true);
        }
        setproofchoosingName(kybData.addressProofName);
      }
    } catch (err) {}
  };

  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>{" "}
      <div>
        <div className="Verification assets_main kyc_page_main">
          <div className="container">
            <div className="table_padding_bottom">
              <Link to="/KybInformation1">
                <h6>
                  {" "}
                  <i class="fa-solid fa-arrow-left-long mr-3"></i>{" "}
                  {t("verification")}
                </h6>
              </Link>

              <div className="row justify-content-center cards">
                <div className="col-lg-5 kyc_information">
                  <h3 className="heading">
                    <span className="inf_no">2</span>
                    {t("business-registration-information")}
                  </h3>
                  <div className="input-groups icons">
                    <h6 className="input-label">{t("registration-type")}</h6>

                    <div className="custom-select-stake kyc-verify my-2 w-100">
                      <div className="input-groups select-wrapper ">
                        <Select
                          options={registrationOptions}
                          value={registrationOptions.find(
                            (option) => option.value === registrationtype
                          )}
                          onChange={handletypeChange}
                          placeholder={t("select-type")}
                          styles={colourStyles}
                          className="reg-select-dropdown "
                        />
                      </div>
                    </div>
                    {registrationtypevallidateref.current && (
                      <p className="errorcss">
                        {registrationtypeErrMsgref.current}
                      </p>
                    )}
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label">{t("registration-number")}</h6>
                    <div className="input-field ref_input">
                      <input
                        type="text"
                        name="registrationnumber"
                        value={registrationnumber}
                        minLength={5}
                        maxLength={30}
                        onChange={handleChange}
                        className=""
                        placeholder={t("regitstration-number-placeholder")}
                      />
                    </div>
                    {regnumvallidateref.current && (
                      <p className="errorcss">{regnumErrMsgref.current}</p>
                    )}
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label">{t("date-of-formation")}</h6>
                    <div className="input-field ref_input">
                      <input
                        type="date"
                        name="Dateofformation"
                        value={Dateofformation}
                        max={getTodayDate()}
                        onChange={handleChange}
                        placeholder={t("enter-date-of-formation")}
                      />
                    </div>
                    {dofvallidateref.current && (
                      <p className="errorcss">{dofErrMsgref.current}</p>
                    )}
                  </div>
                  <div className="fiat_deposit_detail country_kyb_dropdown">
                    <h6 className="input-label">{t("country-formation")}</h6>
                    {/* <div className="input-field ref_input kyc_country_dropdown"></div> */}
                    <div className="custom-select-stake kyc-verify my-2 w-100">
                      <div className="input-groups select-wrapper ">
                        <Select
                          options={options}
                          value={options.find(
                            (option) => option.value === Country
                          )}
                          onChange={handleCountryChange}
                          placeholder={t("select-country")}
                          styles={colourStyles}
                          className="reg-select-dropdown "
                        />
                      </div>
                    </div>
                  </div>
                  {countryvallidateref.current && (
                    <p className="errorcss">{countryErrMsgref.current}</p>
                  )}
                  <div className="input-groups icons">
                    <h6 className="input-label">
                      {t("please-attach-your-business")}
                    </h6>
                  </div>

                  {/* cor-formation */}
                  <div className="driver_license_content">
                    {/* <input
                      id="check-corporate"
                      type="checkbox"
                      className="checkbox-round"
                      onClick={handleCheck}
                    /> */}
                    <label htmlFor="check-corporate" className="check-label">
                      {t("corporate-formation")}
                    </label>
                  </div>
                  {/* {selectCheck && ( */}
                  <div className="driver_license_doc">
                    <div className="upload">
                      <input
                        type="file"
                        name="image"
                        className="image_upload_kyc"
                        onChange={(e) =>
                          imageUpload("corporateFormation", e.target.files[0])
                        }
                      />

                      {corporateFormationproofLoad == false ? (
                        validcorporateFormationproof == 0 ? (
                          <>
                            <div className="inner_frst_display">
                              <i class="ri-upload-2-line"></i>
                              <p>{t("click-to-upload-file")}</p>
                            </div>
                          </>
                        ) : validcorporateFormationproof == 1 ? (
                          <i class="bi bi-file-earmark-bar-graph"></i>
                        ) : (
                          <img
                            src={corporateFormationproofref.current}
                            className="up_im_past"
                            alt="National Id Front"
                          />
                        )
                      ) : (
                        <div className="inner_frst_display">
                          <i class="fa-solid fa-spinner fa-spin fa-2xl"></i>
                        </div>
                      )}
                      {/* <i class="ri-upload-2-line"></i>
                        <p>{t("click-to-upload-file")}</p> */}
                    </div>
                  </div>
                  {/* // )} */}

                  {corporateFormvallidateref.current && (
                    <p className="errorcss">{corporateFormErrMsgref.current}</p>
                  )}

                  {/* company share */}
                  <div className="driver_license_content">
                    {/* <input
                      id="check-comp"
                      type="checkbox"
                      class="checkbox-round"
                      onClick={handleCheckComp}
                    /> */}
                    <label htmlFor="check-comp" className="check-label">
                      {t("company-share-registry")}
                    </label>
                  </div>
                  {/* {selectCompCheck && ( */}
                  <div className="driver_license_doc">
                    <div className="upload">
                      <input
                        type="file"
                        name="image"
                        className="image_upload_kyc"
                        onChange={(e) =>
                          imageUpload("companyShareRegistry", e.target.files[0])
                        }
                      />

                      {companyproofLoad == false ? (
                        validcompanyproof == 0 ? (
                          <>
                            <div className="inner_frst_display">
                              <i class="ri-upload-2-line"></i>
                              <p>{t("click-to-upload-file")}</p>
                            </div>
                          </>
                        ) : validcompanyproof == 1 ? (
                          <i class="bi bi-file-earmark-bar-graph"></i>
                        ) : (
                          <img
                            src={companyproofref.current}
                            className="up_im_past"
                            alt="National Id Front"
                          />
                        )
                      ) : (
                        <div className="inner_frst_display">
                          <i class="fa-solid fa-spinner fa-spin fa-2xl"></i>
                        </div>
                      )}
                      {/* <i class="ri-upload-2-line"></i>
                        <p>Click to upload file</p> */}
                    </div>
                  </div>
                  {/* )} */}
                  {companyproofvallidateref.current && (
                    <p className="errorcss">{companyproofErrMsgref.current}</p>
                  )}

                  {/* proof */}
                  <div className="driver_license_content mb-4">
                    <label htmlFor="check-proof" className="check-label ">
                      {t("proof-business-operating-address")}
                    </label>
                  </div>

                  <div className="driver_license_content">
                    <input
                      id="check-proof"
                      type="checkbox"
                      className={
                        addressProofref.current == ""
                          ? "checkbox-round"
                          : "checkbox-round-checked"
                      }
                    />
                    <div className="input-field ref_input upload_icon kyc_country_dropdown w-200">
                      <Select
                        options={addressProofOptions}
                        onChange={handleAddressProofChange}
                        placeholder={ proofchoosingName =="" ||proofchoosingName ==null ?t("select-operating-address"):proofchoosingName}
                        value={addressProofOptions.find(
                          (option) => option.value === addressProofref.current 
                        )}
                        className="reg-select-dropdown "
                        styles={colourStyles}
                        defaultValue={proofchoosingName}
                      />
                    </div>
                  </div>

                {proofchoosingName =="" ||proofchoosingName ==null ||proofchoosingName == undefined?
                "":
                <div className="driver_license_doc">
                    <div className="upload">
                      <input
                        type="file"
                        name="image"
                        className="image_upload_kyc"
                        onChange={(e) =>
                          imageUpload("addressProof", e.target.files[0])
                        }
                      />

                      {addressProofLoad == false ? (
                        validaddressProof == 0 ? (
                          <>
                            <div className="inner_frst_display">
                              <i class="ri-upload-2-line"></i>
                              <p>{t("click-to-upload-file")}</p>
                            </div>
                          </>
                        ) : validaddressProof == 1 ? (
                          <i class="bi bi-file-earmark-bar-graph"></i>
                        ) : (
                          <img
                            src={addressProofref.current}
                            className="up_im_past"
                            alt="National Id Front"
                          />
                        )
                      ) : (
                        <div className="inner_frst_display">
                          <i class="fa-solid fa-spinner fa-spin fa-2xl"></i>
                        </div>
                      )}

                      {addressproofnameref.current == "" ? (
                        ""
                      ) : (
                        <div className="">
                          <input
                            className="proofs_name"
                            disabled
                            value={addressproofnameref.current}
                          ></input>
                        </div>
                      )}
                    </div>
                  </div>
                  }
                  <>
                    {addressProofvallidateref.current && (
                      <p className="errorcss">
                        {addressProofErrMsgref.current}
                      </p>
                    )}
                  </>
              {
                buttonLoader ==true ?
              <div className="Submit my-4">
                                  <Link>
                                    <button>{t("loading")}...</button>
                                  </Link>
                                </div>
                :
                <div className="Submit my-4" onClick={submit}>
                                  <Link>
                                    <button>{t("next")}</button>
                                  </Link>
                                </div>
              }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
