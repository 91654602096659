export const env = {
  // apiHost: "http://localhost:3033/",
  // frontUrl: "http://localhost:3000/",
  apiHost: "https://fidex.beleaftechnologies.com:3033/",
  frontUrl: "https://fidex.beleaftechnologies.com/",
  upload_preset: "f3l6gwtp",
  cloud_name: "dg2vjjlpm",
  // apisocketurl:"http://localhost:3001/",
  apisocketurl: "https://fidex.beleaftechnologies.com:3001/",
};
